import React, { Component } from 'react';
import DataTable from '../common/DataTable';
import DialogBox from './DialogBox'
import RuleClassDialogBox from './RuleClassDialogBox';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Edit from '@material-ui/icons/Edit';
import AddCircle from '@material-ui/icons/AddCircle';
import Replay from '@material-ui/icons/Replay';
import { Tooltip } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import AlertDialog from '../common/AlertDialog';
import axios from 'axios';
import Grid from "@material-ui/core/Grid";
import AuthenticatedPage from "../AuthenticatedPage";
import { withStyles } from '@material-ui/core/styles';
import Delete from "@material-ui/icons/Delete";
import CustomToolbarSelect from '../common/CustomToolbarSelect';
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../common/Utils';
import ScriptDialogBox from './ScriptDialogBox';
import _ from 'lodash';

const styles = theme => ({
  layout: {
    width: 'auto',
    minHeight: "400px",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 9,
    paddingTop: theme.spacing.unit * 8,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: 100,
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: 'auto'
    },
  },
  title: {
    paddingTop: theme.title.paddingTop,
    color: theme.title.color,
    fontSize: theme.typography.pxToRem(47),
    lineHeight: theme.title.lineHeight
  }
})
class ManageRules extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      openrule: false,
      ruledata: '',
      rules: [],
      ruleclass: [],
      update: false,
      customization: [],
      undoall: false,
      undo: false,
      fetchingRec: true,
      scripts: []
    }
  }
  message = () => {
    alert('Failed! Reason User must login or provide a valid access_token ')
  }
  async componentDidMount() {
    const { userDetails } = this.props.currentUser
    try {
      let data = {
        access_token: userDetails.accessToken,
        role: userDetails.role,
      }
      let result = await axios.all([
        axios.post('/api/user/rules', data),
        axios.post('/api/user/ruleclass', data),
        axios.post('/api/user/customization', data),
        axios.post('/api/user/scripts', data),
      ])
      if (result[0].data.err)
        alert('Failed reason ' + result[0].data.err)
      this.setState({
        fetchingRec: false,
        update: true,
        rules: result[0].data.rules,
        ruleclass: result[1].data.ruleclass,
        customization: result[2].data.customization,
        scripts: result[3].data.scripts,
      })
    } catch (err) {
      //console.log(err)
    }
  }
  handleAddRule = () => {
    this.setState({
      openrule: true,
      ruledata: '',
      disabled: false
    });
  };
  handleClickOpenEditRule = (index) => {
    //console.log(index)
    const rules = this.state.rules;
    this.setState({
      openrule: true,
      ruledata: rules[index],
      disabled: true
    });
  };
  handleDelete = (delArray) => {
    const { userDetails } = this.props.currentUser
    var ruleclass = this.state.ruleclass;
    var delIds = [];
    delArray.forEach(del => {
      let className = ruleclass[del['dataIndex']][0];
      delIds.push(className);
      let data = {
        access_token: userDetails.accessToken,
        role: userDetails.role,
        className: className
      }
      axios.post('/api/user/deleteclass', data).then((res) => { showSnackbar(res.data.snackbar, this) }).catch((err) => showSnackbarError(err.message, this))
    });
    ruleclass = ruleclass.filter(fil => !delIds.includes(fil[0]));
    this.setState({ ruleclass: ruleclass });
  }
  handleScriptDelete = (delArray) => {
    const { userDetails } = this.props.currentUser
    var scripts = this.state.scripts;
    var delIds = [];
    delArray.forEach(del => {
      let scriptId = scripts[del['dataIndex']][2];
      delIds.push(scriptId);
      let data = {
        access_token: userDetails.accessToken,
        role: userDetails.role,
        scriptId: scriptId
      }
      axios.post('/api/user/deleteScript', data).then((res) => { showSnackbar(res.data.snackbar, this) }).catch((err) => showSnackbarError(err.message, this))
    });
    scripts = scripts.filter(fil => !delIds.includes(fil[2]));
    this.setState({ scripts: scripts });
  }

  handleUpdate = async () => {
    const { userDetails } = this.props.currentUser
    let data = {
      access_token: userDetails.accessToken,
      role: userDetails.role,
    }
    let result = await axios.post('/api/user/ruleclass', data)
    this.setState({ ruleclass: result.data.ruleclass })
  }
  handleScriptUpdate = async () => {
    const { userDetails } = this.props.currentUser
    let data = {
      access_token: userDetails.accessToken,
      role: userDetails.role,
    }
    let result = await axios.post('/api/user/scripts', data)
    this.setState({ scripts: result.data.scripts })
  }
  handleUpdateRule = async () => {
    const { userDetails } = this.props.currentUser
    let data = {
      access_token: userDetails.accessToken,
      role: userDetails.role,
    }
    let result = await axios.post('/api/user/rules', data)
    this.setState({ rules: result.data.rules })
  }
  handleUpdateViolation = async () => {
    const { userDetails } = this.props.currentUser
    let data = {
      access_token: userDetails.accessToken,
      role: userDetails.role,
    }
    let result = await axios.post('/api/user/customization', data)
    this.setState({ customization: result.data.customization })
  }
  handleUndoCustomization = () => {
    const { userDetails } = this.props.currentUser
    this.handleClickOpenModal()
    let data = {
      access_token: userDetails.accessToken,
      role: userDetails.role,
    }
    try {
      axios.post('/api/user/undoCustomization', data).then((res) => {
        showSnackbar(res.data.snackbar, this)
        this.handleUpdateRule()
      }).catch((err) => showSnackbarError(err.message, this))
    } catch (err) {
      showSnackbarError('Failed to undo customizations', this)
    }
  }
  handleUndoById = (delArray) => {
    const { userDetails } = this.props.currentUser
    var customization = this.state.customization;
    var delIds = [];
    delArray.forEach(del => {
      let id = customization[del['dataIndex']][5];
      delIds.push(id);
      let data = {
        access_token: userDetails.accessToken,
        role: userDetails.role,
        id: id
      }
      axios.post('/api/user/UndoCustomizationById', data).then((res) => { showSnackbar(res.data.snackbar, this) }).catch((err) => showSnackbarError(err.message, this))
    });
    customization = customization.filter(fil => !delIds.includes(fil[5]));
    this.setState({ customization: customization });

  }


  handleUndoAllCustomization = () => {
    const { userDetails } = this.props.currentUser
    this.handleClickOpenModal()
    let data = {
      access_token: userDetails.accessToken,
      role: userDetails.role,
    }
    try {
      axios.post('/api/user/undoAllCustomization', data).then((res) => {
        this.handleUpdateViolation()
        showSnackbar(res.data.snackbar, this)
      }).catch((err) => showSnackbarError(err.message, this))
    } catch (err) {
      showSnackbarError('Failed to undo customizations', this)
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
      ruledata: ''
    });
  };

  handleAddScript = () => {
    this.setState({
      openScript: true,
      scriptdata: ''
    });
  };

  handleClickOpenEdit = (data) => {
    //console.log(data)
    this.setState({
      open: true,
      ruledata: data,
    });
  };
  handleClickScriptEdit = (index) => {
    //console.log(data)
    this.setState({
      openScript: true,
      scriptdata: this.state.scripts[index],
    });
  };

  handleClickOpenModal = () => {
    this.setState({
      open: false,
      openrule: false,
      undoall: false,
      undo: false,
      openScript: false,
    });
  }
  handleUndoAll = () => {
    this.setState({
      undoall: true,
    });
  };
  handleUndo = () => {
    this.setState({
      undo: true,
    });
  }


  render() {
    const { classes } = this.props

    const ruleColumns = [
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Tooltip key={'edit'} title={'Edit'}   >
              <IconButton value='edit' className={classes.iconButton} onClick={() => this.handleClickOpenEditRule(tableMeta.rowIndex)} >
                <Edit className={classes.icon} />
              </IconButton>
            </Tooltip>
          }
        }
      },
      {
        name: "Rule Id",
        options: {
          filter: false,
        }
      },
      {
        name: "Rule Name",
        options: {
          filter: false,
        }
      },
      {
        name: "Rule Class",
        options: {
          filter: false,
        }
      },
      {
        name: "Active",
        options: {
          filter: false,
        }
      },
      {
        name: "Apply On",
        options: {
          filter: false,
        }
      },

    ]

    const violationColumns = [
      {
        name: "Url",
        options: {
          filter: false,
        }
      },
      {
        name: "Rule Name",
        options: {
          filter: false,
        }
      },
      {
        name: "Rule Description",
        options: {
          filter: false,
        }
      },
      {
        name: "Remediation Suggestion",
        options: {
          filter: false,
        }
      },
      {
        name: "Source",
        options: {
          filter: false,
        }
      },
    ];
    const ruleClassColumns = [
      {
        name: "Actions",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Tooltip key={'edit'} title={'Edit'}   >
              <IconButton value='edit' className={classes.iconButton} onClick={() => this.handleClickOpenEdit(tableMeta.rowData)} >
                <Edit className={classes.icon} />
              </IconButton>
            </Tooltip>
          }
        }
      },
      {
        name: "Rule Class Name",
        options: {
          filter: false,
        }
      },
      {
        name: "Code",
        options: {
          filter: false,
        }
      },
    ];
    const scriptColumns = [
      {
        name: "Actions",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Tooltip key={'edit'} title={'Edit'}   >
              <IconButton value='edit' className={classes.iconButton} onClick={() => this.handleClickScriptEdit(tableMeta.rowIndex)} >
                <Edit className={classes.icon} />
              </IconButton>
            </Tooltip>
          }
        }
      },
      {
        name: "Domain",
        options: {
          filter: false,
        }
      },
      {
        name: "Script",
        options: {
          filter: false,
        }
      },
    ];

    const ruleAction = [
      { label: "Undo All Customizations", callback: this.handleUndo, icon: Replay },
      { label: "Add New Rule", callback: this.handleAddRule, icon: AddCircle }
    ]

    const violationsAction = [
      { label: "Undo All Customizations", callback: this.handleUndoAll, icon: Replay }
    ]

    const ruleClassAction = [
      { label: "Create Rule Class ", callback: this.handleClickOpen, icon: AddCircle }
    ]

    const scriptAction = [
      { label: "Add Script", callback: this.handleAddScript, icon: AddCircle }
    ]


    return (
      <React.Fragment>
        <AlertDialog
          open={this.state.undo}
          handleClickOpenModal={this.handleClickOpenModal}
          title={'This will remove all your Rule Customizations'}
          footer={[{ label: "Cancel", callback: this.handleClickOpenModal },
          { label: "OK", callback: this.handleUndoCustomization }]}
        >
        </AlertDialog>
        <AlertDialog
          open={this.state.undoall}
          handleClickOpenModal={this.handleClickOpenModal}
          title={'This will remove all your Violation Customizations'}
          footer={[{ label: "Cancel", callback: this.handleClickOpenModal },
          { label: "OK", callback: this.handleUndoAllCustomization }]}
        >
        </AlertDialog>
        {this.state.openrule && <DialogBox handleUpdateRule={this.handleUpdateRule} rowData={this.state.ruledata} handleClickModal={this.handleClickOpenModal} open={this.state.openrule} disabled={this.state.disabled} />}

        {this.state.open && <RuleClassDialogBox handleUpdate={this.handleUpdate} rowData={this.state.ruledata} handleClickModal={this.handleClickOpenModal} open={this.state.open} />}

        {this.state.openScript && <ScriptDialogBox handleUpdate={this.handleScriptUpdate} rowData={this.state.scriptdata} handleClickModal={this.handleClickOpenModal} open={this.state.openScript} />}

        <main className={classes.layout1}>

          <Grid container>
            <Grid item xs={12}>
              <p className={classes.title} align="left">Rules</p>
              <DataTable
                handleUpdateRule={this.handleUpdateRule}
                data={this.state.rules}
                selectableRows={false}
                download={true}
                textLabelsBody={this.state.fetchingRec ? "Fetching Records..." : "No Records Found"}
                expandableRows={true}
                renderExpandableRow={(rowData, rowMeta) => {
                  const colSpan = rowData.length + 1;
                  const { rules } = this.state;
                  return (<React.Fragment>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={colSpan}>
                        <b>Check For:</b> {rules[rowMeta.dataIndex][6]}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={colSpan}>
                        <b>Severity:</b> {rules[rowMeta.dataIndex][7]}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={colSpan}>
                        <b>Properties:</b> {rules[rowMeta.dataIndex[8]]}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={colSpan}>
                        <b>Description:</b> {rules[rowMeta.dataIndex][9]}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={colSpan}>
                        <b>Remediation Suggestion:</b> {rules[rowMeta.dataIndex][10]}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                  );
                }}
                columns={ruleColumns}
                toolBarAction={ruleAction}
                title="Manage Rules"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <p className={classes.title} align="left">Skipped Violations</p>
              <DataTable
                handleUpdateViolation={this.handleUpdateViolation}
                data={this.state.customization}
                expandableRows={true}
                textLabelsBody={this.state.fetchingRec ? "Fetching Records..." : "No Records Found"}
                renderExpandableRow={(rowData, rowMeta) => {
                  const colSpan = rowData.length + 1;
                  const { customization } = this.state;
                  return (
                    <React.Fragment>
                      <TableRow>
                        <TableCell />
                        <TableCell colSpan={colSpan}>
                          <b>Element:</b> {_.unescape(customization[rowMeta.dataIndex][6])}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell />
                        <TableCell colSpan={colSpan}>
                          <b>Priority:</b> {customization[rowMeta.dataIndex][7]}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                }}
                columns={violationColumns}
                toolBarAction={violationsAction}
                title="Skipped Violations"
                onRowsDelete={this.handleUndoById}
                customToolbarSelect={(selectedRows, displayData, setSelectedRows) => <CustomToolbarSelect actionList={[{ title: "Remove", eventCallback: this.handleUndoById, alertTitle: "This will remove selected Violation(s)", icon: Delete }]} selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <p className={classes.title} align="left">Rule Classes</p>
              <DataTable
                data={this.state.ruleclass}
                columns={ruleClassColumns}
                expandableRows={false}
                selectableRows={true}
                textLabelsBody={this.state.fetchingRec ? "Fetching Records..." : "No Records Found"}
                toolBarAction={ruleClassAction}
                title="Rule Classes"
                // onRowsDelete={this.handleDelete} 
                customToolbarSelect={(selectedRows, displayData, setSelectedRows) => <CustomToolbarSelect actionList={[{ title: "Delete", eventCallback: this.handleDelete, alertTitle: "Do you want to delete selected Rule class(s)?", icon: Delete }]} selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />}
              />
            </Grid>
          </Grid>
          <Grid container key={this.state.scripts.length || 0}>
            <Grid item xs={12}>
              <p className={classes.title} align="left">Scripts</p>
              <DataTable
                data={this.state.scripts || []}
                columns={scriptColumns}
                selectableRows={true}
                expandableRows={false}
                textLabelsBody={this.state.fetchingRec ? "Fetching Records..." : "No Records Found"}
                toolBarAction={scriptAction}
                title="Scripts"
                // onRowsDelete={this.handleDelete} 
                customToolbarSelect={(selectedRows, displayData, setSelectedRows) => <CustomToolbarSelect actionList={[{ title: "Delete", eventCallback: this.handleScriptDelete, alertTitle: "Do you want to delete selected Script(s)?", icon: Delete }]} selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />}
              />
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AuthenticatedPage(withSnackbar(ManageRules)));