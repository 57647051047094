import React, { Component } from 'react';
import Highcharts from 'highcharts/highstock';
import {
  HighchartsStockChart, Chart, withHighcharts, XAxis, YAxis, Title, Legend,
  SplineSeries, Navigator, RangeSelector, Tooltip, Credits
} from 'react-jsx-highstock';

class App extends Component {

  render() {
    const { data, showHisDetailsfromGraph } = this.props;
    return (
      <div className="app card">

        <HighchartsStockChart>

          <Chart zoomType="x" />

          <Credits enabled={false}></Credits>

          <Title>Trending Graph</Title>

          <Legend>
            <Legend.Title>Key</Legend.Title>
          </Legend>

          <RangeSelector>
            <RangeSelector.Button count={1} type="day">1d</RangeSelector.Button>
            <RangeSelector.Button count={7} type="day">7d</RangeSelector.Button>
            <RangeSelector.Button count={1} type="month">1m</RangeSelector.Button>
            <RangeSelector.Button type="all">All</RangeSelector.Button>
            {/* <RangeSelector.Input boxBorderColor="#7cb5ec" /> */}
          </RangeSelector>

          <Tooltip />

          <XAxis>
            <XAxis.Title>Time</XAxis.Title>
          </XAxis>


          {Object.keys(data).map(his => {
            return <YAxis key={his}>
              <YAxis.Title>{his}</YAxis.Title>
              <SplineSeries id={his} name={his} data={data[his]} onclick={(e) => showHisDetailsfromGraph(his, e.point.x)} />
            </YAxis>
          })}

          <Navigator>
            <Navigator.Series seriesId="profit" />
            <Navigator.Series seriesId="twitter" />
          </Navigator>
        </HighchartsStockChart>

      </div>
    );
  }
}

export default withHighcharts(App, Highcharts);