import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = {
    card: {
      maxWidth: 500,
      minWidth: 300
    }
}

class Highchart extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardContent>
            <div>
              <HighchartsReact
                constructorType={"chart"}
                highcharts={Highcharts}
                options={this.props.options}
              />
            </div>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}
Highchart.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Highchart);
