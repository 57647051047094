import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import logo from '../assets/WhiteLogo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { Link } from "react-router-dom";
import Footer from './Footer'
import CssBaseline from '@material-ui/core/CssBaseline';
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../common/Utils';
import AlertDialog from '../common/AlertDialog';


const styles = theme => ({
    menuButton: {
        marginLeft: theme.spacing.unit * 22,
        height: 46,
        display: "flex",
        [theme.breakpoints.down(600 + theme.spacing.unit * 3 * 2)]: {
            marginLeft: 0,
        },
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    bdyMinHght: {
        minHeight: "400px",
        marginBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 8
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2196F3'
    },
    toolbarTitle: {
        flex: 1,
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 10,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginBottom: 100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    navlink: {
        textTransform: theme.navbar.textTransform,
        fontWeight: 500,
        textDecoration: 'none',
        color: theme.navbar.color,
        fontSize: theme.typography.pxToRem(20),
        marginRight: theme.spacing.unit * 22,
        [theme.breakpoints.down(600 + theme.spacing.unit * 3 * 2)]: {
            marginRight: 0,
        },
    },
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: theme.button.width,
        padding: theme.button.padding,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
    error: {
        color: theme.error.color
    },
    inputLabel: {
        fontSize: 30,
    }
})
class Register extends Component {

    state = {
        fname: '',
        lname: '',
        company: '',
        email: '',
        password: '',
        mobile: '',
        userError: '',
        alert: false,
        title: ''
    }
    componentDidMount() {
        ValidatorForm.addValidationRule('ispass', (value) => {
            if (value.length < 8) {
                return false;
            }
            return true;
        });
    }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit = () => {
        let data = {
            firstName: this.state.fname,
            lastName: this.state.lname,
            companyname: this.state.company,
            email: this.state.email,
            phone: this.state.mobile,
            password: this.state.password
        }
        axios.post('/api/user/register', data).then((res) => {
            if (res.data.snackbar.variant === "error") {
                showSnackbar(res.data.snackbar, this)
            } else this.setState({ alert: true, title: res.data.snackbar.message })
        }).catch(() => showSnackbarError("Registration Failed Error!", this))
    }
    SnackMsg = () => {
        this.setState({ userError: true })
    }
    Focus = () => {
        this.setState({ userError: false })
    }
    render() {
        const { classes } = this.props;
        const { alert, title } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                            <img src={logo} className={classes.menuButton} alt="logo" />
                        </Typography>
                        <Link tabIndex={0} to="/login" className={classes.navlink} >Login</Link>
                    </Toolbar>
                </AppBar>
                <main className={classes.layout}>
                    <Grid container>
                        <Grid item sm={12} style={{ paddingTop: 70 }} align="left">
                            <div className={classes.title}>Register</div><br />
                            <ValidatorForm onSubmit={this.handleSubmit} onError={this.SnackMsg} instantValidate={true}>
                                <Grid container spacing={8} alignItems="flex-end">
                                    <Grid item sm={6}>
                                        <TextValidator
                                            label="First Name"
                                            tabIndex={100}
                                            id="fname"
                                            name="fname"
                                            onChange={this.handleChange}
                                            value={this.state.fname}
                                            fullWidth
                                            required
                                            onFocus={this.Focus}
                                            InputLabelProps={{
                                                className: classes.label,
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br /><br />
                                <Grid container spacing={8} alignItems="flex-end">
                                    <Grid item sm={6}>
                                        <TextValidator
                                            label="Last Name"
                                            tabIndex={101}
                                            id="lname"
                                            name="lname"
                                            onChange={this.handleChange}
                                            value={this.state.lname}
                                            fullWidth
                                            required
                                            onFocus={this.Focus}
                                            InputLabelProps={{
                                                className: classes.label,
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br /><br />
                                <Grid container spacing={8} alignItems="flex-end">
                                    <Grid item sm={6}>
                                        <TextValidator
                                            label="Company Name"
                                            tabIndex={102}
                                            id="company"
                                            name="company"
                                            onChange={this.handleChange}
                                            value={this.state.company}
                                            fullWidth
                                            required
                                            onFocus={this.Focus}
                                            InputLabelProps={{
                                                className: classes.label,
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br /><br />
                                <Grid container spacing={8} alignItems="flex-end">
                                    <Grid item sm={6}>
                                        <TextValidator
                                            label="Email"
                                            tabIndex={103}
                                            id="email"
                                            onChange={this.handleChange}
                                            name="email"
                                            value={this.state.email}
                                            withRequiredValidator
                                            validators={['required', 'isEmail']}
                                            errorMessages={['this field is required', 'email is not valid']}
                                            fullWidth
                                            required
                                            onFocus={this.Focus}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: classes.label,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br /><br />
                                <Grid container spacing={8} alignItems="flex-end">
                                    <Grid item sm={6}>
                                        <TextValidator
                                            label="Mobile"
                                            tabIndex={104}
                                            id="mobile"
                                            name="mobile"
                                            type="number"
                                            onChange={this.handleChange}
                                            value={this.state.mobile}
                                            fullWidth
                                            required
                                            onFocus={this.Focus}
                                            InputLabelProps={{
                                                className: classes.label,
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br /><br />
                                <Grid container spacing={8} alignItems="flex-end">
                                    <Grid item sm={6}>
                                        <TextValidator
                                            label="Password"
                                            tabIndex={105}
                                            id="password"
                                            fullWidth
                                            required
                                            onChange={this.handleChange}
                                            name="password"
                                            type="password"
                                            withRequiredValidator
                                            value={this.state.password}
                                            validators={['required', 'ispass']}
                                            errorMessages={['this field is required', 'Password should contain minimum 8 character']}
                                            onFocus={this.Focus}
                                            InputLabelProps={{
                                                className: classes.label,
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <p className={classes.error} >{this.state.userError}</p>
                                <br /><br />
                                <Grid container>
                                    <Grid item>
                                        
                                        <Button type="submit" value='register' variant="contained" tabIndex={106} className={classes.button}>
                                            REGISTER
                                            <FontAwesomeIcon icon={faList} className={classes.rightIcon} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </Grid>
                        <Grid item sm={1}></Grid>
                    </Grid>
                </main>
                <Footer />
                <AlertDialog
                    title={title}
                    open={alert}
                    footer={[{
                        label: "OK", callback: () => {
                            this.setState({ alert: false })
                            this.props.history.push('/login');
                        }
                    }]}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withSnackbar(Register));