import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';

const customServerlanguageDetector = {
    type: 'languageDetector',
    async: true, // flags below detection to be async
    detect: callback => {
        return axios.get('/api/resourceLoading/getLanguage').then(response => {
            callback(response.data.language)
        })
    },
    init: () => { },
    cacheUserLanguage: () => { },
};

i18n
    .use(XHR)
    .use(customServerlanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        debug: true,
        whitelist: ['en', 'hi'],
        backend: {
            loadPath: '/api/resourceLoading/loadLocales/{{lng}}',
            parse: function (data) {
                let parsedData = JSON.parse(data);
                return parsedData[Object.keys(parsedData)[0]];
            }
        },
        fallbackLng: false,
        interpolation: {
            escapeValue: false,
        },
    });


export default i18n;