import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import AuthenticatedPage from "../AuthenticatedPage";
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    bdyMinHght: {
        minHeight: "400px",
        marginBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 2
    },
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    },
    name: {
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.name.lineHeight
    },
})
class MyAccount extends Component {
    state = {}
    render() {
        const { classes } = this.props
        let activedate = this.props.loggedInUserObj.userDetails.createdOn
        return (
            <React.Fragment>
                <div className={classes.bdyMinHght}>
                    <Grid container>

                        <Grid item style={{}} align="left">
                            <div className={classes.title} >Account Information:</div><br />
                        </Grid>
                        <Grid item sm={1}></Grid>
                    </Grid>
                    <Grid container>

                        <Grid item sm={10} style={{}} align="left">
                            <div className={classes.name} >Plan: {this.props.loggedInUserObj.userDetails.plan}</div><br />
                            <div className={classes.name} >Active from: {new Date(activedate).toString()}</div><br />
                        </Grid>
                        <Grid item sm={1}></Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AuthenticatedPage(MyAccount))