import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import AuthenticatedPage from "../../AuthenticatedPage";
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../../common/Utils';
import AlertDialog from '../../common/AlertDialog';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    bdyMinHght: {
        minHeight: "400px",
        marginBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 8
    },
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    },
    name: {
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.name.lineHeight
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    script: {
        width: 200,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 430
        },
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 9,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: 120,
        padding: theme.button.padding,
        marginLeft: theme.spacing.unit,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
})
class Userway extends Component {

    state = {
        email: '',
        url: '',
        open: false,
    }

    handleChange = event => { this.setState({ [event.target.name]: event.target.value }) }

    handleCancel = () => { this.setState({ email: "", url: "" }) }

    handleClose = () => this.setState({ open: false })

    handleCopy = () => this.copyButton.click();

    handleSubmit = () => {
        const { userDetails } = this.props.currentUser
        const { email, url } = this.state;
        let data = {
            postData: {
                email: email,
                url: url,
            },
            access_token: userDetails.accessToken,
            role: userDetails.role,
        }
        axios.post('/api/user/getUserwayScript', data).then((res) => {
            if (res.data && res.data.script) {
                this.setState({ open: true, script: res.data.script })
            } else {
                showSnackbar(res.data.snackbar, this)
            }
        }).catch((err) => showSnackbarError(err.message, this))
    }

    render() {
        const { classes } = this.props
        const andiLink = <a title={"Accessbot plugin"} href="../plugin.zip">Accessbot Plugin</a>;
        const dialogContent = <>
            <TextField className={classes.script} multiline rowsMax="4" defaultValue={this.state.script} />
            <CopyToClipboard text={this.state.script}
                onCopy={() => this.setState({ copied: true })}>
                <span ref={ref => this.copyButton = ref} />
            </CopyToClipboard>
        </>
        let footer = [{ label: "Copy Script", callback: this.handleCopy }, { label: "Cancel", callback: this.handleClose }];
        return (
            <Grid item style={{ padding: "50px 20px 0px 20px" }} align="left">
                <AlertDialog
                    open={this.state.open}
                    handleClose={this.handleClose}
                    content={dialogContent}
                    title={"Accessibility Script"}
                    footer={footer}
                />
                <div className={classes.title} >Accessbot Plugin</div><br />
                <p>Through Accessbot Plugin we can scan current site html content </p>
                <ol>
                    <li>Download plugin for chrome here {andiLink}</li>
                </ol>
                <br />
            </Grid>
        );
    }
}

export default withStyles(styles)(AuthenticatedPage(withSnackbar(Userway)));