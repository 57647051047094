import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator-vivek';
import AuthenticatedPage from "../AuthenticatedPage";
import { showSnackbarError } from '../common/Utils';
import WithDashboardScan from './withDashboardScan';


const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        justifyContent: 'center',
    },
    media: {
        height: 200,
        paddingTop: '56.25%', // 16:9
    },
    paper: {
        padding: theme.spacing.unit,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    scancolor: {
        color: '#26a69',
    },
    url: {
        width: 80, wordWrap: 'break-word'
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 9,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
    color: {
        color: theme.name.color,
    },
    name: {
        color: theme.name.color,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.name.lineHeight
    },
    options: {
        display: 'inline',
        paddingRight: 5,
        color: theme.options.color,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.options.lineHeight
    },
    formcontrollabel: {
        paddingLeft: 15,
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: 240,
        padding: theme.button.padding,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
    p: {
        fontSize: theme.typography.pxToRem(36),
        color: theme.p.color,
        fontWeight: theme.p.fontWeight
    },
});

const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

class ImageText extends Component {
    constructor(props) {
        super(props);
        const { url } = this.props.currentUrl.scanUrlResult;
        this.state = {
            url,
            disableCall: false,
            data: null
        }
    }

    async componentDidMount() {
        ValidatorForm.addValidationRule('isUrl', (value) => {
            if (!pattern.test(value)) {
                return true;
            }
            return true;
        });
    }

    handleSubmit = async () => {
        this.setState({ disableCall: true, data: null })
        this.getLabelForImage()
    }

    getLabelForImage = async () => {
        let data = {
            url: this.state.url
        }
        axios.post('/api/ai/images/detectText', data)
            .then(res => {
                console.log(res.data)
                if (res.data.text) {
                    let text = res.data.text
                    this.setState({ data: res.data, text, disableCall: false })
                } else {
                    this.setState({ text: "text not found", disableCall: false })
                }
            },
                (error) => {
                    console.log(error)
                    this.setState({ text: "text not found", disableCall: false })
                }
            )
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value, image: null })
    }

    render() {
        const { classes, currentUser } = this.props;
        return (
            <React.Fragment>
                <main className={classes.layout1}>
                    <Grid container>
                        <Grid item style={{ paddingTop: 0 }} align="left">
                            <div className={classes.name}>Generate Text for images</div><br />
                        </Grid>
                    </Grid>
                    <ValidatorForm className={classes.root} onSubmit={() => this.setState({ isFromHistory: false, scannedOn: new Date() }, () => this.handleSubmit())} onError={() => showSnackbarError("Invalid URL", this)}>
                        <Grid container >
                            <Grid item sm={4} style={{ paddingLeft: 10 }}>
                                <TextValidator
                                    label="Image URL"
                                    id="url"
                                    tabIndex={100}
                                    name="url"
                                    value={this.state.url}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                    validators={['required', 'isUrl']}
                                    errorMessages={['']}
                                    InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item sm={4} style={{ paddingTop: 50 }}>
                                <Button type='submit' value='submit' tabIndex={101} disabled={this.state.disableCall} className={classes.button}>
                                    Scan Text
                                <SendIcon className={classes.rightIcon} />
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                    {this.state.disableCall &&
                        <Grid container>
                            <Grid item sm={4}>
                                <div style={{ paddingLeft: 35 }}>{"Getting text for image...."}<LinearProgress className={classes.scancolor} /></div>
                            </Grid>
                        </Grid>
                    }
                    {this.state.text &&
                        <>
                            <Grid container >
                                <Grid item sm={4} style={{ paddingLeft: 10, marginTop: 10 }}>
                                    <Card >
                                        <CardMedia
                                            className={classes.media}
                                            image={this.state.url}
                                            title="Paella dish"
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item style={{ paddingLeft: 10, marginTop: 20 }}>
                                    <Typography variant="body2" component="body2">
                                        {"Scanned Text : "}
                                    </Typography>
                                    <Typography variant="body2" component="body2">
                                        {this.state.text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    }
                </main>
            </React.Fragment>
        );
    }
}
ImageText.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(AuthenticatedPage(WithDashboardScan(withSnackbar(ImageText)))));
