import React from "react";

const defaultUserObj = {
    firstName: 'guest',
    isAuthenticated: false,
    plan: '',
    accessToken: '',
    createdOn:'',
    url:'',
    email:''
};

const UserLoggedInContext = React.createContext({
    userDetails: defaultUserObj,
    changeUser: () => {
        
    }
}
);

const UserLoggedInContextProvider = UserLoggedInContext.Provider;
const UserLoggedInContextConsumer = UserLoggedInContext.Consumer;
export { UserLoggedInContext as default, defaultUserObj, UserLoggedInContextProvider, UserLoggedInContextConsumer};
