import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import AuthenticatedPage from "../AuthenticatedPage";
import { withStyles } from '@material-ui/core/styles';
import JiraConfig from './JiraConfig';
import SplunkConfig from './SplunkConfig';
import SonarQConfig from './SonarQConfig';
import AlertDialog from '../common/AlertDialog';
import { allowUser } from '../common/Utils';
import Button from '@material-ui/core/Button';
import FilterNone from '@material-ui/icons/FilterNone';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const styles = theme => ({
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    },
    name: {
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.name.lineHeight
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 9,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: theme.button.width,
        padding: theme.button.padding,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            display: "none",
        }
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
})
class Settings extends Component {
    render() {
        const { classes, currentUser } = this.props
        return (
            <React.Fragment>
                <main className={classes.layout1}>
                    <Grid item sm={1}></Grid>
                    <Grid container>
                        <Grid item sm={7} style={{ padding: "50px 20px 0px 20px" }} align="left">
                            <div className={classes.title} >Access Token:</div><br />
                            <TextField
                                id="standard-name"
                                label="Access Token"
                                value={this.props.loggedInUserObj.userDetails.accessToken === 'guest' ? 'null' : this.props.loggedInUserObj.userDetails.accessToken}
                                margin="normal"
                                multiline={true}
                                rowsMax="2"
                                fullWidth={true}
                                InputLabelProps={{
                                    className: classes.label,
                                    shrink: true,
                                }}
                            />
                            <div className={"copy-button"}>
                                <CopyToClipboard onCopy={this.onCopy} text={this.props.loggedInUserObj.userDetails.accessToken === 'guest' ? 'null' : this.props.loggedInUserObj.userDetails.accessToken} >
                                    <Button type='copy' value='copy' variant="contained" tabIndex={100} className={classes.button}>Copy API Token<FilterNone className={classes.rightIcon} /></Button>
                                </CopyToClipboard>
                            </div>
                        </Grid>
                    </Grid>
                    {allowUser(currentUser, "JIRA_CONFIGURATION") && <Grid container>
                        <Grid item sm={10} style={{ padding: "50px 20px 0px 20px" }} align="left">
                            <div className={classes.title} >Jira Configuration:</div><br />
                            <JiraConfig />
                        </Grid>
                    </Grid>}
                    <Grid container>
                        <Grid item sm={10} style={{ padding: "50px 20px 0px 20px" }} align="left">
                            <div className={classes.title} >Splunk Configuration:</div><br />
                            <SplunkConfig />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={10} style={{ padding: "50px 20px 0px 20px" }} align="left">
                            <div className={classes.title} >SonarQube Configuration:</div><br />
                            <SonarQConfig />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item sm={10} style={{ padding: "50px 20px 0px 20px" }} align="left">
                            <div className={classes.title} >User Agent:</div><br />
                            <TextField
                                id="standard-name"
                                label="User Agent"
                                value={navigator.userAgent}
                                margin="normal"
                                multiline={true}
                                rowsMax="5"
                                fullWidth={true}
                                InputLabelProps={{
                                    className: classes.label,
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </main>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AuthenticatedPage(Settings));