import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import SendIcon from '@material-ui/icons/Send';
import Close from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AuthenticatedPage from "../AuthenticatedPage";
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../common/Utils';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: 120,
        padding: theme.button.padding,
        marginLeft: theme.spacing.unit,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    paper:{
        padding:20
    }
})

class JiraConfig extends React.Component {

    state = {
        userId: '',
        jiraHost: '',
        jiraUserName: '',
        jiraPassword: '',
        jiraAuthToken: '',
        jiraProject: ''
    }

    componentDidMount() {
        this.fetchJiraConfig()
    }

    fetchJiraConfig = () => {
        const { userDetails } = this.props.loggedInUserObj
        let data = {
            userId: userDetails.userId,
            access_token: userDetails.accessToken,
        }
        axios.post('/api/user/getJiraConfig', data).then((res) => {
            if (res.data && res.data.jiraConfigData) {
                const { userId, jiraHost, jiraUserName, jiraPassword, jiraAuthToken, jiraProject } = res.data.jiraConfigData
                this.setState({ userId, jiraHost, jiraUserName, jiraPassword, jiraAuthToken, jiraProject })
            }
        }).catch((err) => showSnackbarError(err.message, this))
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit = () => {
        const { jiraHost, jiraUserName, jiraPassword, jiraAuthToken, jiraProject } = this.state
        const { userDetails } = this.props.loggedInUserObj
        const data = {
            userId: userDetails.userId,
            role: userDetails.role,
            access_token: userDetails.accessToken,
            jiraConfigData: {
                userId: userDetails.userId,
                jiraHost,
                jiraUserName,
                jiraPassword,
                jiraAuthToken,
                jiraProject
            }
        }
        axios.post('/api/user/updateJiraCredentials', data).then((res) => {
            showSnackbar(res.data.snackbar, this)
        }).catch(err => {
            console.log(err)
            showSnackbarError(err.message, this)
        })
    }

    handleCancel = () => {
        this.fetchJiraConfig()
    }

    render() {
        const { classes } = this.props;
        return <React.Fragment>
            <ValidatorForm onSubmit={this.handleSubmit} instantValidate={true}>
                <Grid container spacing={8} alignItems="flex-end">

                    <Grid item sm={6}>
                        <TextValidator
                            label="Jira Host Name"
                            name="jiraHost"
                            id="jiraHost"
                            tabIndex={100}
                            onChange={this.handleChange}
                            value={this.state.jiraHost}
                            fullWidth
                            required
                            InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <br /><br />
                <Grid container spacing={8} alignItems="flex-end">

                    <Grid item sm={6}>
                        <TextValidator
                            label="Jira User Name"
                            tabIndex={101}
                            name="jiraUserName"
                            id="jiraUserName"
                            required
                            onChange={this.handleChange}
                            value={this.state.jiraUserName}
                            fullWidth
                            InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <br /><br />

                <Grid container spacing={8} alignItems="flex-end">

                    <Grid item sm={6}>
                        <TextValidator
                            label="Jira Password"
                            name="jiraPassword"
                            id="jiraPassword"
                            tabIndex={102}
                            onChange={this.handleChange}
                            value={this.state.jiraPassword}
                            fullWidth
                            InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <br /><br />
                <Grid container spacing={8} alignItems="flex-end">

                    <Grid item sm={6}>
                        <TextValidator
                            label="Jira AuthToken"
                            name="jiraAuthToken"
                            id="jiraAuthToken"
                            tabIndex={102}
                            onChange={this.handleChange}
                            value={this.state.jiraAuthToken}
                            fullWidth
                            InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <br /><br />
                <Grid container spacing={8} alignItems="flex-end">

                    <Grid item sm={6}>
                        <TextValidator
                            label="Jira Project ID"
                            name="jiraProject"
                            id="jiraProject"
                            tabIndex={102}
                            onChange={this.handleChange}
                            value={this.state.jiraProject}
                            fullWidth
                            required
                            InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <br /><br />
                <Grid item sm={4} style={{ paddingTop: 25 }}>
                    <Button type='submit' value='submit' tabIndex={104} className={classes.button}>
                        Save
                        <SendIcon className={classes.rightIcon} />
                    </Button>
                    <Button onClick={this.handleCancel} value='submit' tabIndex={105} className={classes.button}>
                        Cancel
                        <Close className={classes.rightIcon} />
                    </Button>
                </Grid>

                <Grid item sm={6} style={{ paddingTop: 25 }}>
                    <Paper className={classes.paper}>
                        <Typography variant="body2" component="body2">
                            Note : 
                            <br/>
                            1. Please make sure the given user has permissions on Issue Type: Bug
                            <br/>
                            2. Please provide password or auth token
                        </Typography>
                    </Paper>
                </Grid>
                

            </ValidatorForm>
        </React.Fragment>
    }
}

export default withStyles(styles)(AuthenticatedPage(withSnackbar(JiraConfig)));