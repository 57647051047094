import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import SendIcon from '@material-ui/icons/Send';
import Close from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AuthenticatedPage from "../AuthenticatedPage";
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../common/Utils';

const styles = theme => ({
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: 120,
        padding: theme.button.padding,
        marginLeft: theme.spacing.unit,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
})

class SplunkConfig extends React.Component {

    state = {
        userId: '',
        splunkHost: '',
        splunkToken: '',
        splunkIndex: ''
    }

    componentDidMount() {
        this.fetchSplunkConfig()
    }

    fetchSplunkConfig = () => {
        const { userDetails } = this.props.loggedInUserObj
        let data = {
            userId: userDetails.userId,
            access_token: userDetails.accessToken,
        }
        axios.post('/api/user/getSplunkConfig', data).then((res) => {
            if (res.data && res.data.splunkConfigData) {
                const { userId, splunkHost, splunkToken, splunkIndex } = res.data.splunkConfigData
                this.setState({ userId, splunkHost, splunkToken, splunkIndex })
            }
        }).catch((err) => showSnackbarError(err.message, this))
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit = () => {
        const { splunkHost, splunkToken, splunkIndex } = this.state
        const { userDetails } = this.props.loggedInUserObj
        const data = {
            userId: userDetails.userId,
            role: userDetails.role,
            access_token: userDetails.accessToken,
            splunkConfigData: {
                userId: userDetails.userId,
                splunkHost,
                splunkToken,
                splunkIndex
            }
        }
        axios.post('/api/user/updateSplunkCredentials', data).then((res) => {
            showSnackbar(res.data.snackbar, this)
        }).catch(err => {
            console.log(err)
            showSnackbarError(err.message, this)
        })
    }

    handleCancel = () => {
        this.fetchSplunkConfig()
    }

    render() {
        const { classes } = this.props;
        return <React.Fragment>
            <ValidatorForm onSubmit={this.handleSubmit} instantValidate={true}>
                <Grid container spacing={8} alignItems="flex-end">

                    <Grid item sm={6}>
                        <TextValidator
                            label="Host Name"
                            name="splunkHost"
                            id="splunkHost"
                            tabIndex={100}
                            onChange={this.handleChange}
                            value={this.state.splunkHost}
                            fullWidth
                            //required
                            InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <br /><br />
                <Grid container spacing={8} alignItems="flex-end">

                    <Grid item sm={6}>
                        <TextValidator
                            label="Token"
                            tabIndex={101}
                            name="splunkToken"
                            id="splunkToken"
                            onChange={this.handleChange}
                            value={this.state.splunkToken}
                            fullWidth
                            //required
                            InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <br /><br />

                <Grid container spacing={8} alignItems="flex-end">

                    <Grid item sm={6}>
                        <TextValidator
                            label="Index"
                            name="splunkIndex"
                            id="splunkIndex"
                            tabIndex={102}
                            onChange={this.handleChange}
                            value={this.state.splunkIndex}
                            fullWidth
                            //required
                            InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <br /><br />
                <Grid item sm={4} style={{ paddingTop: 25 }}>
                    <Button type='submit' value='submit' tabIndex={104} className={classes.button}>
                        Save
                        <SendIcon className={classes.rightIcon} />
                    </Button>
                    <Button onClick={this.handleCancel} value='submit' tabIndex={105} className={classes.button}>
                        Cancel
                        <Close className={classes.rightIcon} />
                    </Button>
                </Grid>

            </ValidatorForm>
        </React.Fragment>
    }
}

export default withStyles(styles)(AuthenticatedPage(withSnackbar(SplunkConfig)));