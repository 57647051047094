import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import AuthenticatedPage from "../AuthenticatedPage";
import axios from 'axios';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../common/Utils';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
});

const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
class ScheduleDialogBox extends React.Component {
    constructor() {
        super()
        this.state = {
            open: false,
            website: '',
            hours: '',
            email: ''
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }


    handleClose = () => {
        this.props.handleClickModal()
    };
    async handleSubmit() {
        const { userDetails } = this.props.currentUser
        let data = {
            access_token: userDetails.accessToken,
            role: userDetails.role,
            website: this.state.website,
            hours: this.state.hours,
            email: this.state.email

        }
        await axios.post('/api/user/addschedulescan', data).then((res) => { showSnackbar(res.data.snackbar, this) }).catch((err) => showSnackbarError(err.message, this))
        this.props.handleUpdate()
        this.props.handleClickModal()
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        ValidatorForm.addValidationRule('isUrl', async (value) => {
            if (!pattern.test(value)) {
                return false;
            }
            return true;
        });
    }
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Dialog
                    onClose={this.props.dismiss}
                    scroll={this.state.scroll}
                    open={this.props.open}
                    aria-labelledby="customized-dialog-title"
                    maxWidth='md'
                >
                    <ValidatorForm onSubmit={this.handleSubmit} >

                        <DialogTitle onClose={this.handleClose}>
                            Create New Entry
                        </DialogTitle>
                        <DialogContent>
                            <TextValidator
                                autoFocus
                                margin="dense"
                                name="website"
                                label="Website"
                                value={this.state.website}
                                onChange={this.handleChange}
                                fullWidth
                                validators={['required', 'isUrl']}
                                errorMessages={['this field is required', 'website is not valid']}
                                InputLabelProps={{
                                    className: classes.label,
                                    shrink: true,
                                }}
                            />
                            <TextValidator
                                margin="dense"
                                name="hours"
                                label="Hours"
                                type="number"
                                value={this.state.hours}
                                onChange={this.handleChange}
                                fullWidth
                                validators={['required']}
                                errorMessages={['this field is required']}
                                InputLabelProps={{
                                    className: classes.label,
                                    shrink: true,
                                }}
                            />
                            <TextValidator
                                label="Email"
                                onChange={this.handleChange}
                                name="email"
                                value={this.state.email}
                                fullWidth
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}
                                InputLabelProps={{
                                    className: classes.label,
                                    shrink: true,
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button type="close" onClick={this.handleClose} color="primary">
                                close
                            </Button>
                            <Button type="submit" value='submit' color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(AuthenticatedPage((withSnackbar(ScheduleDialogBox))))