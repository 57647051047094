import  React from "react";
import { Redirect} from 'react-router-dom';
import withUser from './components/WithUser';
import _ from 'lodash';

function tieAuthorization(Component)
{
  return class withAuthorization extends React.Component
  {
      // makeAuthenticatedAPICall = async (method,url,data) => {
      //   console.log(method,url);
      //   try{
      //     this.props.currentUser.userTimer.setServerAccessed();
      //   let result = await axios({
      //        method: method,
      //        url: url,
      //        data: data
      //      })
      //      if (result.status === 200) {
      //       return result;
      //      }
      //      else{
      //        return null;
      //      }
      //   }catch (ex) {
      //     console.log("ERROR:",ex);
      // }
      // }
      render(){
          const {currentUser:userObj} = this.props;
          return(
               (
                      (userObj.userDetails.isAuthenticated 
                        ||
                         (localStorage.getItem("accessToken") && localStorage.getItem("isAuthenticated") 
                            && localStorage.getItem("downloadPdf") ) 
                            ? <Component loggedInUserObj={userObj}  { ...this.props} /> 
                                :  <Redirect to="/abcd" />)
 
              )
          ) 
  }
 }
}

const AuthenticatedPage = Component =>{
   
 return withUser(tieAuthorization(Component));
    
}



export default AuthenticatedPage;