import React from 'react';
import auth0 from 'auth0-js';

export default class Callback extends React.Component {

    componentDidMount() {

        var webAuth = new auth0.WebAuth({
            clientID: 't8AFi4cu-OuqpUhSL-SPeSNFVkSkTK2V',
            domain: 'dev-h3t5o1c8.auth0.com',
            redirectUri: 'http://example.com',
            audience: `https://dev-h3t5o1c8.auth0.com/api/v2/`,
            scope: 'read:current_user',
            responseType: 'token id_token'
        });

        webAuth.parseHash({ hash: window.location.hash }, function (err, authResult) {
            console.log(authResult)
            if (err) {
                return console.log(err);
            }

            webAuth.client.userInfo(authResult.accessToken, function (err, user) {
                console.log(user)
            });
        });
    }

    render() {
        return null;
    }
}