import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import AlertDialog from "./AlertDialog";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const defaultToolbarSelectStyles = theme => ({
    Button: {
        marginRight: "24px",
        top: "15%",
        position: "relative",
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        padding: theme.button.padding,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
    icon: {
        marginLeft: theme.spacing.unit,
    },
});

class CustomToolbarSelect extends React.Component {

    state = {
        open: false,
    }

    handleClose = () => this.setState({ open: false })

    render() {
        const { classes, selectedRows, actionList = [] } = this.props;
        const { callback } = this.state;

        let footer = [{ label: "Cancel", callback: this.handleClose }];

        if (callback) footer.push({ label: "OK", callback: callback });

        return (
            <React.Fragment>
                <AlertDialog
                    open={this.state.open}
                    handleClose={this.handleClose}
                    title={this.state.alertTitle}
                    footer={footer}
                />
                <div className={"custom-toolbar-select"}>
                    {actionList.map(action => action.type && action.type === "button" && !isWidthDown('sm', this.props.width) ?
                        <Button key={action.title} className={classes.Button} variant="contained" onClick={() => action.alertTitle ? this.setState({ open: true, alertTitle: action.alertTitle, callback: () => action.eventCallback(selectedRows.data) }) : action.eventCallback(selectedRows.data)}>
                            {action.title}<action.icon className={classes.icon} />
                        </Button>
                        :
                        <Tooltip key={action.title} title={action.title}>
                            <IconButton className={classes.iconButton} onClick={() => action.alertTitle ? this.setState({ open: true, alertTitle: action.alertTitle, callback: () => action.eventCallback(selectedRows.data) }) : action.eventCallback(selectedRows.data)}>
                                <action.icon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(withWidth()(CustomToolbarSelect));