import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import AuthenticatedPage from "../../AuthenticatedPage";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';


const styles = theme => ({
    bdyMinHght: {
        minHeight: "400px",
        marginBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 8
    },
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    },
    name: {
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.name.lineHeight
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 9,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
})
class ANDI extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            url : ''
        }
    }
    componentDidMount()
    {
        axios.get('/api/user/getDomine').then(res => {
            if(res.data != undefined)
            {
                this.setState({
                    url : res.data.host_url
                })
            }
        });
    }
    render() {
        const { classes } = this.props;        
        var hostLink = this.state.url + "/Accessibility/andi/andi.js"
        const andiLink = <a title={"Accessibility ANDI widget"} href={`javascript:void((function(){andiScript=document.createElement('script');andiScript.setAttribute('src','${hostLink}');document.body.appendChild(andiScript)})());`}>Accessbot ANDI</a>;
        
        return (
            <Grid container>
                <Grid item sm={1}></Grid>
                <Grid item style={{ padding: "50px 20px 0px 20px" }} align="left">
                    <div className={classes.title} >Accessbot ANDI</div><br />
                    <p>Drag and Drop this link: {andiLink} into your browser's favorites/bookmark bar.</p>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" gutterBottom> Chrome </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <ol>
                                <li>Ensure that Chrome's Bookmarks Bar is visible (Ctrl + Shift + B)</li>
                                <li>Now drag and drop this link: {andiLink} to the Bookmarks Bar</li>
                                <li>ANDI is now installed to Chrome and ready to be launched</li>
                            </ol>
                            <Typography>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" gutterBottom> Firefox </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <ol>
                                <li>Ensure that Firefox's Bookmarks Toolbar is visible</li>
                                <li>Right click this link: {andiLink} and select "Bookmark This Link". Add it to the Bookmarks Toolbar. Alternatively, keyboard users running Windows can tab to this link: {andiLink}, press ctrl+shift+F10 (which brings up a context menu) and select "Bookmark This Link". Add it to the Bookmarks Toolbar</li>
                                <li>ANDI is now installed to Firefox and ready to be launched</li>
                            </ol>
                            <Typography>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" gutterBottom> Internet Explorer </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <ol>
                                <li>Ensure that IE's Favorites Bar is visible</li>
                                <li>Right click this link: {andiLink} and select "Add to favorites bar". Alternatively, keyboard users running Windows can tab to this link: ANDI, press ctrl+shift+F10 (which brings up a context menu) and select "Add to favorites bar"</li>
                                <li>ANDI is now installed to IE and ready to be launched</li>
                            </ol>
                            <Typography>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" gutterBottom> Safari </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <ol>
                                <li>Ensure that Safari's Favorites Bar is visible (Command + Shift + B)</li>
                                <li>Drag and drop this link: {andiLink} to the Favorites Bar</li>
                                <li>ANDI is now installed to Safari and ready to be launched</li>
                            </ol>
                            <Typography>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" gutterBottom> Edge </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <ol>
                                <li>The version of Edge you have may not allow the direct installation of favelets (such as ANDI). As a workaround...</li>
                                <li>Install ANDI to Chrome or Firefox</li>
                                <li>Use Edge to import favorites from Chrome or Firefox</li>
                                <li>Once imported, ANDI can be moved into Edge's Favorites Bar for quick launching</li>
                            </ol>
                            <Typography>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <br/>
                    *ANDI is an open source tool from SSA. To know more about this tool <a target="_blank" title="https://github.com/SSAgov/ANDI" href="https://github.com/SSAgov/ANDI">click here</a>.
                </Grid>
                <Grid item sm={1}></Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(AuthenticatedPage(ANDI));