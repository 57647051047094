import React, { useState } from 'react';

import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from './Navbar'
import Appbar from './Appbar'
import AuthenticatedPage from "../AuthenticatedPage";
import Dashboard from './Dashboard'
import DashboardReport from './DashboardReport'
import ManageRules from './ManageRules'
import History from './History'
import ScheduleScan from './ScheduleScan'
import Settings from './Settings'
import MyAccount from './MyAccount'
import Resetpassword from './Resetpassword'
//import Footer from './Footer'
import Plugins from '../components/Plugins/Plugins.jsx'
import ImageLabels from '../components/ImageLabels.jsx'
import ImageText from '../components/ImageText.jsx'
import BrokenLinks from '../components/BrokenLinks.jsx'
import SeoDashboard from '../components/SeoDashboard.jsx'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { dashboard, UserDashboardContextProvider } from "../DashboardContext"
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Sidebar, Topbar, Footer } from './NavBarComponents';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 56,

        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64
        }
    },
    shiftContent: {
        paddingLeft: 240
    },
    content: {
        height: '100%',
        padding: theme.spacing(4)
    },

}));


const UserAccount = props => {
    const { children, match } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    const [driver, setDriver] = useState(false)
    const [scanUrlResult, setScanUrlResult] = useState(dashboard)

    const changeUrl = (dashboardObj) => {
        if (dashboardObj) {
            setScanUrlResult(dashboardObj);
            //this.setState({ scanUrlResult: dashboardObj });
        }
        else {
            setScanUrlResult(dashboard);
            //this.setState({ scanUrlResult: dashboard });
        }
    };

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop
            })}
        >
            <Topbar onSidebarOpen={handleSidebarOpen} />
            <Sidebar
                onClose={handleSidebarClose}
                open={shouldOpenSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
            />
            <main className={classes.content}>
                <Container maxWidth={'lg'}>
                    <UserDashboardContextProvider value={{ driver, scanUrlResult, changeUrl }}>
                        <Switch>
                            <Route path={`${match.url}/dashboard/:id`} component={Dashboard} />
                            <Route path={`${match.url}/dashboard`} render={(props) => <Dashboard {...props} driver={driver} />} />
                            <Route path={`${match.url}/rules`} component={ManageRules} />
                            <Route path={`${match.url}/history`} render={(props) => <History {...props} storeHistory={changeUrl} />} />
                            <Route path={`${match.url}/schedule`} component={ScheduleScan} />
                            <Route path={`${match.url}/settings`} render={(props) => <Settings {...props} handleDriver={setDriver} />} />
                            <Route path={`${match.url}/profile`} component={MyAccount} />
                            <Route path={`${match.url}/resetpassword`} component={Resetpassword} />
                            <Route path={`${match.url}/dashboardreport`} render={(props) => <DashboardReport {...props} driver={driver} />} />
                            <Route path={`${match.url}/plugins`} component={Plugins} />
                            <Route path={`${match.url}/image-labels`} component={ImageLabels} />
                            <Route path={`${match.url}/image-text`} component={ImageText} />
                            <Route path={`${match.url}/broken-links`} component={BrokenLinks} />
                            <Route path={`${match.url}/seo-dashboard`} component={SeoDashboard} />
                            <Redirect to={`${match.url}/dashboard`} ></Redirect>
                        </Switch>
                    </UserDashboardContextProvider>
                </Container>
                {/* <Footer /> */}
            </main>
        </div>
    )


}


export default AuthenticatedPage(withWidth()(UserAccount));


// class UserAccount extends React.Component {
//     constructor() {
//         super()
//         this.state = {
//             //driver: false,
//             scanUrlResult: dashboard,
//             changeUrl: this.changeUrl,
//         }
//         this.handleDriver = this.handleDriver.bind(this)
//     }
//     // handleDriver(value) {
//     //     this.setState({ driver: value })
//     // }
//     changeUrl = (dashboardObj) => {
//         if (dashboardObj) {
//             this.setState({ scanUrlResult: dashboardObj });
//         }
//         else {
//             this.setState({ scanUrlResult: dashboard });
//         }
//     };
//     render() {
//         const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
//             defaultMatches: true
//         });

//         const { match, classes } = this.props;

//         var navbar = () => {
//             if (isWidthUp('sm', this.props.width)) {
//                 return <Navbar updateSize={this.props.loggedInUserObj.updateSize} name={this.props.loggedInUserObj.userDetails.firstName} />
//             } else {
//                 return <Appbar />
//             }
//         }

//     }
// }

// export default AuthenticatedPage(withWidth()(withStyles(styles)(UserAccount)))
