import React from 'react';
import MUIDataTable from 'mui-datatables';
import AuthenticatedPage from '../AuthenticatedPage';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  textLeft: {
    textAlign: 'left',
  },
  iconPadding: {
    paddingLeft: 5,
  },
  spinner: {
    padding: 12,
    position: 'relative',
    top: 5,
  },
});

class DataTable extends React.Component {
  render() {
    const { classes } = this.props;

    const {
      title = 'Data Table',
      columns,
      data,
      toolBarAction = [],
      download = false,
      filter = false,
      onRowsDelete,
      expandableRows = false,
      renderExpandableRow,
      customToolbarSelect,
      rowsPerPage = 10,
      selectableRows = false,
      onFilterChange = () => null,
      filterType = 'checkbox',
      textLabelsBody = 'No Records Found',
    } = this.props;

    const customToolbar = (
      <React.Fragment>
        {toolBarAction.map((icon) => {
          if (icon.spinner) {
            return (
              <span key={icon.label} className={classes.spinner}>
                {icon.icon}
              </span>
            );
          } else {
            return (
              <Tooltip key={icon.label} title={icon.label}>
                <IconButton
                  value={icon.label}
                  className={classes.iconButton}
                  onClick={() => icon.callback()}
                >
                  <icon.icon />
                </IconButton>
              </Tooltip>
            );
          }
        })}
      </React.Fragment>
    );

    const options = {
      filterType: filterType,
      responsive: 'stacked',
      rowsPerPage: rowsPerPage,
      selectableRows: data && data.length > 0 && selectableRows ? true : false,
      customToolbar: () => customToolbar,
      onFilterChange: (column, filterList) =>
        onFilterChange(column, filterList),
      print: false,
      download: download,
      filter: filter,
      onRowsDelete: (delArray) => onRowsDelete(delArray['data']),
      expandableRows: expandableRows,
      renderExpandableRow: (rowData, rowMeta) =>
        renderExpandableRow(rowData, rowMeta),
      customToolbarSelect: customToolbarSelect,
      rowsPerPageOptions: [5, 10, 20, 100],
      textLabels: {
        body: {
          noMatch: textLabelsBody,
        },
      },
    };

    return (
      <MUIDataTable
        key={textLabelsBody + data.length}
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    );
  }
}

export default withStyles(styles)(DataTable);
