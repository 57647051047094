import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import FormLabel from '@material-ui/core/FormLabel';
import axios from 'axios'
import AuthenticatedPage from "../AuthenticatedPage";
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../common/Utils';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
});


class DialogBox extends React.Component {
    constructor() {
        super()
        this.state = {
            open: false,
            active: '',
            ruleid: '',
            rulename: '',
            applyon: '',
            checkfor: '',
            severity: '',
            properties: '',
            description: '',
            remedsug: ''
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }


    handleClose = () => {
        this.props.handleClickModal()
    };
    componentDidMount() {
        const res = this.props.rowData
        this.setState({
            ruleid: res[0],
            rulename: res[1],
            ruleclass: res[2],
            active: res[3],
            applyon: res[4],
            checkfor: res[6],
            severity: res[7],
            properties: res[8],
            description: res[9],
            remedsug: res[10],
        })
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSubmit(event) {
        event.preventDefault()
        const { userDetails } = this.props.currentUser
        let data = {
            access_token: userDetails.accessToken,
            role: userDetails.role,
            rulename: this.state.rulename,
            ruleclass: this.state.ruleclass,
            active: (this.state.active === "Yes" ? true : false),
            applyon: this.state.applyon,
            checkfor: this.state.checkfor,
            severity: this.state.severity,
            properties: this.state.properties,
            description: this.state.description,
            remedsug: this.state.remedsug,
            ruleid: this.state.ruleid,
            ruleUser: this.props.loggedInUserObj.userDetails.email
        }
        axios.post('/api/user/addrule', data).then((res) => {
            this.props.handleClickModal()
            this.props.handleUpdateRule()
            showSnackbar(res.data.snackbar, this)
        }).catch((err) => showSnackbarError(err.message, this))
    }
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>

                <Dialog
                    onClose={this.props.dismiss}
                    scroll={this.state.scroll}
                    open={this.props.open}
                    aria-labelledby="customized-dialog-title"
                    maxWidth='md'
                >
                    <ValidatorForm onSubmit={this.handleSubmit} >

                        <DialogTitle id="form-dialog-title" onClose={this.handleClose}>Rule Information</DialogTitle>
                        <DialogContent style={{ maxWidth: '50%' }}>
                            <TextValidator
                                autoFocus
                                margin="dense"
                                name="ruleid"
                                label="Rule Id"
                                value={this.state.ruleid}
                                disabled={this.props.disabled}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                fullWidth
                            />
                            <TextValidator
                                margin="dense"
                                name="rulename"
                                label="Rule Name"
                                value={this.state.rulename}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                fullWidth
                            />
                            <TextValidator
                                margin="dense"
                                name="ruleclass"
                                label="Rule Class"
                                value={this.state.ruleclass}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                fullWidth
                            />
                            <FormLabel component="legend">Active</FormLabel>
                            <RadioGroup
                                row
                                aria-label="Active"
                                name="active"
                                className={classes.group}
                                value={this.state.active}
                                onChange={this.handleChange}
                            >
                                <FormControlLabel value="Yes" control={<Radio />} label="Active" />
                                <FormControlLabel value="No" control={<Radio />} label="Inactive" />
                            </RadioGroup>
                            <TextValidator
                                margin="dense"
                                name="applyon"
                                label="Apply On"
                                value={this.state.applyon}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                fullWidth
                            />
                            <TextValidator
                                margin="dense"
                                name="checkfor"
                                label="Check For"
                                value={this.state.checkfor}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                fullWidth
                            />
                            <RadioGroup
                                aria-label="Severity"
                                name="severity"
                                className={classes.group}
                                value={this.state.severity}
                                onChange={this.handleChange}
                            >
                                <FormControlLabel value="Critical" control={<Radio />} label="Critical" />
                                <FormControlLabel value="Major" control={<Radio />} label="Major" />
                                <FormControlLabel value="Minor" control={<Radio />} label="Minor" />
                            </RadioGroup>
                            <TextValidator
                                margin="dense"
                                name="properties"
                                label="Properties"
                                value={this.state.properties}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <TextValidator
                                margin="dense"
                                name="description"
                                label="Description"
                                value={this.state.description}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <TextValidator
                                margin="dense"
                                name="remedsug"
                                label="Remediation Suggestion"
                                value={this.state.remedsug}
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button value='close' onClick={this.handleClose} color="primary">
                                Close
                            </Button>
                            <Button value='save' type="submit" color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </ValidatorForm>

                </Dialog>
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(AuthenticatedPage(withSnackbar(DialogBox)))
