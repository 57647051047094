import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
//import { connect } from 'react-redux';

const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: 'fit-content'
	},
	avatar: {
		width: 60,
		height: 60
	},
	name: {
		marginTop: theme.spacing(1)
	}
});

class Profile extends React.Component {

	render() {
		const { classes, ...rest } = this.props;
		const user = {
			name: this.props.uname,
			avatar: 'https://static.onecompiler.com/images/blank-profile.png',
			//bio: 'Brain Director'
		};
		let lsUserAuth = localStorage.getItem('UserAuth');
		if (lsUserAuth) {
			lsUserAuth = JSON.parse(lsUserAuth);
		}
		if (lsUserAuth && lsUserAuth.firstName) {
			user.name = lsUserAuth.firstName;
		}

		return (
			<div
				{...rest}
				className={clsx(classes.root)}
			>
				<Avatar
					alt="Person"
					className={classes.avatar}
					component={RouterLink}
					src={user.avatar}
					to="/account"
				/>
				<Typography
					className={classes.name}
					variant="h5"
				>
					{user.name || 'Anonymous'}
				</Typography>
				<Typography variant="body2">{user.bio}</Typography>
			</div>
		);

	}

}

Profile.propTypes = {
	className: PropTypes.string
};

// const mapStateToProps = state => ({
// 	uname: state.user.name
// });

//export default connect(mapStateToProps, {})(withStyles(styles)(Profile));

export default withStyles(styles)(Profile);


