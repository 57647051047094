import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import { withSnackbar } from 'notistack';
import AuthenticatedPage from "../AuthenticatedPage";
import { showSnackbar, showSnackbarError } from '../common/Utils';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});


class ScriptDialogBox extends React.Component {
    constructor() {
        super()
        this.state = {
            open: false,
            domineName: '',
            scriptContent: '',
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }


    handleClose = () => {
        this.props.handleClickModal()
    };
    handleSubmit(event) {
        event.preventDefault()
        const { userDetails } = this.props.currentUser
        let data = {
            access_token: userDetails.accessToken,
            role: userDetails.role,
            domineName: this.state.domineName,
            scriptContent: this.state.scriptContent,
            id: this.state.id
        }
        axios.post('/api/user/addscript', data).then((res) => {
            showSnackbar(res.data.snackbar, this)
            this.props.handleUpdate()
            this.props.handleClickModal()
        }).catch((err) => showSnackbarError(err.message, this))
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        let res = this.props.rowData;
        this.setState({ scriptContent: res[1], domineName: res[0], id: res[2] })
    }
    render() {
        return (
            <React.Fragment>
                <Dialog
                    onClose={this.props.dismiss}
                    scroll={this.state.scroll}
                    open={this.props.open}
                    aria-labelledby="customized-dialog-title"
                    maxWidth='md'
                >
                    <ValidatorForm onSubmit={this.handleSubmit} >
                        <DialogTitle id="form-dialog-title" onClose={this.handleClose}>Script</DialogTitle>
                        <DialogContent>
                            <TextValidator
                                autoFocus
                                margin="dense"
                                name="domineName"
                                label="Domain"
                                value={this.state.domineName || ''}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                style={{ width: '100%' }}
                            />
                            <TextValidator
                                label="Script"
                                margin="dense"
                                name="scriptContent"
                                multiline
                                rows={10}
                                value={this.state.scriptContent || ''}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant="outlined"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button value='close' onClick={this.handleClose} color="primary">
                                Close
                            </Button>
                            <Button type="submit" value='save' color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(AuthenticatedPage(withSnackbar(ScriptDialogBox)))