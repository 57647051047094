import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  AppBar,
  Button,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  makeStyles,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';

import AccessTime from '@material-ui/icons/AccessTime';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import DoneAll from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import logo from '../assets/WhiteLogo.png';

import ViolationCharts from './ViolationCharts';
import DataTable from '../common/DataTable';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing.unit,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  scancolor: {
    color: '#26a69',
  },
  url: {
    width: 80,
    wordWrap: 'break-word',
  },
  layout: {
    width: 'auto',
    minHeight: '400px',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 9,
    paddingTop: theme.spacing.unit * 8,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: 100,
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: 'auto',
    },
  },
  name: {
    color: theme.name.color,
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.name.lineHeight,
  },
  options: {
    display: 'inline',
    paddingRight: 5,
    color: theme.options.color,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.options.lineHeight,
  },
  formcontrollabel: {
    paddingLeft: 15,
  },
  label: {
    fontSize: theme.typography.pxToRem(25),
    position: theme.label.position,
    textAlign: theme.label.textAlign,
  },
  button: {
    backgroundColor: theme.button.backgroundColor,
    color: theme.button.color,
    width: 240,
    padding: theme.button.padding,
    '&:hover': {
      background: theme.button.backgroundColor,
    },
  },
  p: {
    fontSize: theme.typography.pxToRem(36),
    color: theme.p.color,
    fontWeight: theme.p.fontWeight,
  },
}));

const Results = ({ results }) => {
  const classes = useStyles();
  const [data, setData] = useState(results || null);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    function calculateData(results) {
      const { executionTime, score, violations, pagesSacanned } = results;
      const minor = results.violations.filter((v) => v.impactLevel === 'Minor');
      const major = results.violations.filter((v) => v.impactLevel === 'Major');
      const critical = results.violations.filter(
        (v) => v.impactLevel === 'Critical'
      );

      const set = new Set();
      violations.forEach((v) => set.add(v.url));
      const pagesWithViolations = [];
      const pagesWithoutViolations = [];
      pagesSacanned.forEach((p) =>
        set.has(p)
          ? pagesWithViolations.push(p)
          : pagesWithoutViolations.push(p)
      );

      const violationsArray = [];
      violations.forEach((v) => {
        const vArr = [];
        vArr.push(
          v.url,
          v.impactLevel,
          v.ruleName,
          v.ruleDescription,
          v.remediationSuggestion,
          v.path,
          v.tags,
          v.sno
        );
        violationsArray.push(vArr);
      });

      return {
        executionTime,
        score,
        violations,
        violationsArray,
        pagesScanned: pagesSacanned,
        minor,
        major,
        critical,
        pagesWithViolations,
        pagesWithoutViolations,
      };
    }
    setData(calculateData(results));
    setReady(true);
  }, []);

  const columns = [
    {
      name: 'Url',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>
              {value}
              <br />
              <div style={{ paddingTop: 10 }}>
                <a
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ paddingRight: 5 }}
                >
                  <OpenInNewIcon fontSize="small" color="action" />
                </a>
              </div>
            </span>
          );
        },
      },
    },
    {
      name: 'Severity',
    },
    {
      name: 'Rule Name',
    },
    {
      name: 'Issue Description',
    },
    {
      name: 'Remediation Suggestion',
    },
    {
      name: 'Source',
    },
    {
      name: 'Tags',
      options: {
        display: false,
      },
    },
    {
      name: 'Sn',
      options: {
        display: false,
      },
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
      }}
    >
      {isReady && data.pagesScanned.length !== 0 ? (
        <>
          <Grid container>
            <Grid item>
              <div className={classes.root}>
                <Chip
                  label={
                    'Scan completed in ' +
                    data.executionTime / 1000 +
                    ' seconds'
                  }
                  icon={<AccessTime />}
                  className={classes.chip}
                />
                <Chip
                  label={'Found ' + data.violations.length + ' violations'}
                  icon={<ErrorOutline />}
                  className={classes.chip}
                />
                <Chip
                  label={'Number of pages scanned ' + data.pagesScanned.length}
                  icon={<DoneAll />}
                  className={classes.chip}
                />
                <>
                  <Chip
                    label={
                      'Number of pages with violation ' +
                      (data.pagesWithViolations
                        ? data.pagesWithViolations.length
                        : 0)
                    }
                    icon={<HighlightOff />}
                    className={classes.chip}
                  />
                  <Chip
                    label={
                      'Number of pages without violation ' +
                      (data.pagesWithoutViolations
                        ? data.pagesWithoutViolations.length
                        : 0)
                    }
                    icon={<DoneIcon />}
                    className={classes.chip}
                  />
                  <Chip
                    label={
                      'Percentage of pages without violation ' +
                      `${(
                        (data.pagesWithoutViolations.length /
                          data.pagesScanned.length) *
                        100.0
                      ).toFixed(2)}` +
                      ' %'
                    }
                    icon={<VerifiedUser />}
                    className={classes.chip}
                  />
                </>
              </div>
            </Grid>
          </Grid>
          <div style={{ width: '100%' }}>
            <Grid style={{ margin: '1rem 0 2rem 0', width: '100%' }} container>
              <Grid item xs={12} style={{ margin: '0 auto', width: '100%' }}>
                <ViolationCharts
                  data={{
                    score: Math.round(data.score),
                    total: data.violations.length,
                    major: data.major.length,
                    minor: data.minor.length,
                    critical: data.critical.length,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <DataTable
                key={0}
                title="REPORT"
                columns={columns}
                data={data.violationsArray}
                expandableRows={false}
                selectableRows={false}
                download={false}
                filter={false}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <p>Please check the url and try again</p>
        </>
      )}
    </div>
  );
};

const ScanPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const [scanUrl, setScanUrl] = useState('');
  const [isScanning, setScanning] = useState(false);
  const [isReady, setReady] = useState(false);
  const [results, setResults] = useState(null);

  const [email, setEmail] = useState('');

  useEffect(() => {
    const url = query.get('url');
    // console.log(url);
    if (url !== null) {
      setScanUrl(url);
      // setTimeout(async () => {
      //   setScanning(true);
      //   await getResults(url);
      // }, 100);
    }
  }, []);

  const getResults = useCallback(
    async (url, email) => {
      setScanning(true);
      setResults(null);
      try {
        const { data } = await axios.post(
          `/api/user/scan-site-legacy?email=${email}`,
          { url }
        );
        setResults(data);
      } catch (e) {
      } finally {
        setReady(true);
        setScanning(false);
      }
    },
    [setResults, setScanning, setReady]
  );

  function handleSubmit(e) {
    setReady(false);
    e.preventDefault();
    getResults(scanUrl, email);
  }

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: '#2196F3' }}>
        <Toolbar>
          <Grid container>
            <Typography variant="h6" color="inherit">
              <img
                src={logo}
                style={{
                  marginLeft: 170,
                  marginRight: 0,
                  height: 46,
                }}
                alt="logo"
              />
            </Typography>
          </Grid>
          <Grid container>
            <Grid item sm={6}></Grid>
            <Grid item>
              <Link
                to="/login"
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  fontSize: '19pt',
                }}
              >
                Login
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div>
        <Typography
          variant="h3"
          component="h1"
          style={{
            textAlign: 'center',
            color: '#2196F3',
            margin: '2rem 1rem 1rem 1rem',
          }}
        >
          Free Accessibility Audit
        </Typography>
        <Link to="/login">Login for more features</Link>
      </div>
      <div style={{ padding: '1rem' }}>
        <Grid container justify="center" alignItems="center">
          <form
            onSubmit={handleSubmit}
            // style={{
            //   display: 'flex',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            // }}
          >
            <List>
              <ListItem>
                <TextField
                  variant="outlined"
                  disabled={isScanning}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  label="Enter Email"
                />
              </ListItem>
              <ListItem>
                <TextField
                  variant="outlined"
                  disabled={isScanning}
                  required
                  onChange={(e) => setScanUrl(e.target.value)}
                  value={scanUrl}
                  label="Enter Site Url"
                />
              </ListItem>
              <ListItem>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isScanning}
                  type="submit"
                >
                  Scan
                </Button>
              </ListItem>
            </List>
          </form>
        </Grid>
        {isScanning ? (
          <CircularProgress style={{ color: '#2196F3', margin: '2rem' }} />
        ) : (
          ''
        )}
        <div>
          {isReady && results !== null && (
            <Results results={{ ...results, url: scanUrl }} />
          )}
        </div>
      </div>
    </>
  );
};

export default ScanPage;
