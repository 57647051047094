import React, { Component } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Highcharts from './Highcharts';
import ViolationCharts from './ViolationCharts';
import DataTable from '../common/DataTable';
import Chip from '@material-ui/core/Chip';
import SendIcon from '@material-ui/icons/Send';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import PictureAsExcel from '@material-ui/icons/Description';
import Email from '@material-ui/icons/Email';
import CloudDownload from '@material-ui/icons/CloudDownload';
import PageView from '@material-ui/icons/Pageview';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import BugReport from '@material-ui/icons/BugReport';
import AccessTime from '@material-ui/icons/AccessTime';
import HighlightOff from '@material-ui/icons/HighlightOff';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import DoneAll from '@material-ui/icons/DoneAll';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import HelpOutline from '@material-ui/icons/HelpOutline';
import {
  ValidatorForm,
  TextValidator,
} from 'react-material-ui-form-validator-vivek';
import AuthenticatedPage from '../AuthenticatedPage';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import WithDashboardScan from './withDashboardScan';
import { Tooltip } from '@material-ui/core';
import Moment from 'react-moment';
import CustomToolbarSelect from '../common/CustomToolbarSelect';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Flag from '@material-ui/icons/Flag';
import Warning from '@material-ui/icons/Warning';
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError, allowUser } from '../common/Utils';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
//import ClipLoader from 'react-spinners/ClipLoader';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import CircularProgress from '@material-ui/core/CircularProgress';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing.unit,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  scancolor: {
    color: '#26a69',
  },
  url: {
    width: 80,
    wordWrap: 'break-word',
  },
  layout: {
    width: 'auto',
    minHeight: '400px',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 9,
    paddingTop: theme.spacing.unit * 8,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: 100,
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: 'auto',
    },
  },
  name: {
    color: theme.name.color,
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.name.lineHeight,
  },
  options: {
    display: 'inline',
    paddingRight: 5,
    color: theme.options.color,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.options.lineHeight,
  },
  formcontrollabel: {
    paddingLeft: 15,
  },
  label: {
    fontSize: theme.typography.pxToRem(25),
    position: theme.label.position,
    textAlign: theme.label.textAlign,
  },
  button: {
    backgroundColor: theme.button.backgroundColor,
    color: theme.button.color,
    width: 240,
    padding: theme.button.padding,
    '&:hover': {
      background: theme.button.backgroundColor,
    },
  },
  p: {
    fontSize: theme.typography.pxToRem(36),
    color: theme.p.color,
    fontWeight: theme.p.fontWeight,
  },
});

const pattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
); // fragment locator
class Dashboard extends Component {
  constructor(props) {
    super(props);
    const {
      options,
      options2,
      critical,
      major,
      minor,
      exectime,
      pagesCount,
      totalviolations,
      type = 'pie',
      reportHeading = '',
      isFromHistory,
      url,
    } = this.props.currentUrl.scanUrlResult;
    this.state = {
      uri: '',
      message: '',
      loading: false,
      driver: false,
      visionAi: false,
      shallowScan: false,
      open: false,
      downloading: false,
      options,
      options2,
      critical,
      major,
      minor,
      exectime,
      pagesCount,
      totalviolations,
      type,
      reportHeading,
      isFromHistory,
      url,
      disableCall: false,
      enableSettingsSection: false,
    };
  }

  async componentDidMount() {
    const { type, isFromHistory } = this.props.currentUrl.scanUrlResult;
    window.scrollTo(0, 0);
    ValidatorForm.addValidationRule('isUrl', (value) => {
      if (!pattern.test(value)) {
        return true;
      }
      return true;
    });
    this.setState({ type: type || 'pie' });
    let historyid = this.props.match.params.id;
    if (historyid) {
      this.handleSubmit('history:' + historyid, historyid);
    } else {
      this.setState({ uri: null });
    }
    this.filterList = [[], [], []];
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleType = (event) => {
    const { options, options2 } = this.props.currentUrl.scanUrlResult;

    this.setState({
      type: event.target.value,
      ruleNameFilter: null,
      severityFilter: null,
    });

    this.props.currentUrl.changeUrl({
      ...this.props.currentUrl.scanUrlResult,
      type: event.target.value,
      options2: {
        ...options2,
        chart: { type: event.target.value },
      },
      options: {
        ...options,
        chart: { type: event.target.value },
      },
    });
  };

  onFilterChange = (filterValue, filterList) => {
    this.props.currentUrl.changeUrl({
      ...this.props.currentUrl.scanUrlResult,
      filterList,
    });
  };
  handleMail = () => {
    window.location.href = `mailto:someone@example.com?Subject=Accessbot%20report&body=Please%20find%20the%20attachement%20below`;
  };
  handleViewReport = () => {
    const data = JSON.stringify(this.props.currentUrl.scanUrlResult);
    localStorage.setItem('data', data);
    // const report= this.props.history.push(`/home/dashboardreport/`,'_blank');
    window.open(`${window.location.origin}/home/dashboardreport`, '_blank');
  };

  handleExcelReport = async () => {
    this.setState({ excelDownloading: true });
    let data = {
      accesstoken: this.props.loggedInUserObj.userDetails.accessToken,
      url: this.props.currentUrl.scanUrlResult.url,
      id: this.props.currentUrl.scanUrlResult.id,
      type: this.props.currentUrl.scanUrlResult.options.chart.type,
      filterList: this.props.currentUrl.scanUrlResult.filterList
        ? this.props.currentUrl.scanUrlResult.filterList.join(':')
        : null,
    };
    axios
      .post('/api/user/downloadExcelReport', data, {
        responseType: 'arraybuffer',
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      .then((res) => {
        const blob = new Blob([res.data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `report-${data.id}.xlsx`;
        link.click();
        this.setState({ excelDownloading: false });
      });
  };

  handleReport = async () => {
    this.setState({ downloading: true });
    let data = {
      accesstoken: this.props.loggedInUserObj.userDetails.accessToken,
      url: this.props.currentUrl.scanUrlResult.url,
      id: this.props.currentUrl.scanUrlResult.id,
      type: this.props.currentUrl.scanUrlResult.options.chart.type,
      filterList: this.props.currentUrl.scanUrlResult.filterList
        ? this.props.currentUrl.scanUrlResult.filterList.join(':')
        : null,
    };
    axios
      .post('/api/user/downloadpdfreport', data, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `report.pdf`;
        link.click();
        this.setState({ downloading: false });
      });
  };

  sleep = async (millis) => {
    return new Promise((resolve) => setTimeout(resolve, millis));
  };

  handleSubmit = async (uri, historyid) => {
    this.timer = 0;
    this.setState({
      loading: true,
      severityFilter: null,
      ruleNameFilter: null,
      message: null,
      disableCall: true,
    });
    this.props.currentUrl.changeUrl({
      options: null,
      options2: null,
    });
    let scanurl;
    if (uri) {
      scanurl = uri;
    } else {
      scanurl = this.state.url;
    }

    let data = {
      access_token: this.props.loggedInUserObj.userDetails.accessToken,
      url: scanurl,
      user_agent: navigator.userAgent,
      driver: this.state.driver,
      visionAi: this.state.visionAi,
    };

    let isSuccess = false;
    let res = null;

    if (historyid != null) {
      res = await axios.post(
        '/api/user/' + (this.state.shallowScan ? 'scanUrl' : 'scansite'),
        data
      );
      this.setState({ url: res.data.url });
    } else {
      if (this.state.shallowScan) {
        res = await axios.post('/api/user/scanUrl', data);
        this.setState({
          loading: false,
          disableCall: false,
        });
      } else {
        let asyncRes = await axios.post(
          '/api/user/' + (this.state.shallowScan ? 'scanUrl' : 'asyncScansite'),
          data
        );
        let resData = {
          access_token: this.props.loggedInUserObj.userDetails.accessToken,
          execId: asyncRes.data.execId,
        };
        while (!isSuccess) {
          res = await axios.post(
            '/api/user/' +
              (this.state.shallowScan ? 'scanUrl' : `asyncScansiteById`),
            resData
          );
          console.log(res,'res');
          if (res.data.snackbar) {
            this.setState({
              loading: false,
              disableCall: false,
            });
            showSnackbar(res.data.snackbar, this);
            break;
          }
          if (res != null) {
            isSuccess = res.data.isSuccess;
            if (res.data.executionTime) {
              this.props.currentUrl.changeUrl({
                exectime: res.data.executionTime,
                pagesCount: res.data.pagesCount,
                totalviolations: res.data.totalViolations,
                pagesWithViolations: res.data.pagesWithViolations,
                pagesWithoutViolations: res.data.pagesWithoutViolations,
                perctWithoutViolations: res.data.perctWithoutViolations,
                isSuccess: isSuccess,
              });
              this.setState({
                loading: true,
                disableCall: true,
              });
            }
          } else {
            this.setState({
              loading: false,
              disableCall: false,
            });
            break;
          }
          await this.sleep(2000);
        }
        // let clearDataRes = await axios.post('/api/user/clearScanData', resData);
        // console.log(clearDataRes);
      }
    }

    if (
      res != null &&
      res.data.violations !== 0 &&
      res.data.violations !== undefined
    ) {
      this.setState({
        loading: false,
        disableCall: false,
      });
      if (!this.state.isFromHistory) {
        this.setState({
          type: 'pie',
          headingURL: 'Scanned ' + data.url + ' ',
          reportHeading: 'Scanned ' + data.url,
        });
      }
      this.props.currentUrl.changeUrl({
        id: res.data.id || historyid,
        url: this.state.url,
        type: 'pie',
        options: {
          series: [
            {
              data: res.data.violate,
              name: '',
              showInLegend: false,
            },
          ],
          xAxis: [{ categories: res.data.violations[0] }],
          chart: { type: 'pie' },
          title: { text: 'VIOLATIONS BY RULE' },
          categories: res.data.violations[0],
          yAxis: {
            min: 0,
            title: {
              text: '',
            },
          },
        },
        options2: {
          series: [
            {
              data: [
                {
                  name: 'Critical',
                  y: res.data.critical.length,
                  color: 'red',
                },
                {
                  name: 'Major',
                  y: res.data.major.length,
                  color: 'brown',
                },
                {
                  name: 'Minor',
                  y: res.data.minor.length,
                  color: 'yellow',
                },
              ],
              maxPointWidth: 50,
              name: '',
              showInLegend: false,
            },
          ],
          xAxis: { categories: res.data.severity[0], crosshair: true },
          yAxis: {
            min: 0,
            title: {
              text: '',
            },
          },
          chart: { type: 'pie' },
          title: { text: 'VIOLATIONS BY SEVERITY' },
        },
        critical: res.data.critical,
        score:
          res.data.test && res.data.test.score
            ? res.data.test.score
            : res.data.score
            ? res.data.score
            : 0,
        major: res.data.major,
        minor: res.data.minor,
        exectime: res.data.time,
        pagesCount: res.data.pagesCount,
        totalviolations: res.data.totalviolations,
        pagesWithViolations: res.data.pagesWithViolations,
        pagesWithoutViolations: res.data.pagesWithoutViolations,
        perctWithoutViolations: res.data.perctWithoutViolations,
        reportHeading: this.state.reportHeading,
        isFromHistory: this.state.isFromHistory,
        scannedOn: this.state.scannedOn,
        isSuccess: true,
        // driver: this.state.driver
      });
    } else if (res.data.snackbar) {
      this.setState({ loading: false });
      showSnackbar(res.data.snackbar, this);
    } else {
      this.setState({
        loading: false,
        message: 'Everything Looks Good! No Violations Found',
      });
    }
  };
  renderExpandableRow = (data, index, rowData) => {
    const colSpan = rowData.length;
    return (
      <React.Fragment>
        <TableRow>
          <TableCell />
          <TableCell colSpan={colSpan}>
            <b>Element:</b> {_.unescape(data[index][6])}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell colSpan={colSpan}>
            <b>Priority: </b>
            {data[index][7]}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell colSpan={colSpan}>
            <b>Tags: </b>
            {data[index][9]}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell colSpan={colSpan}>
            <b>Level: </b>
            {data[index][11]}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };
  handleChangeSwitch = (event, target) => {
    this.setState({ [target]: event.target.checked });
  };
  handleDisableRule = (disableArray) => {
    const reportData = [
      ...this.props.currentUrl.scanUrlResult.critical,
      ...this.props.currentUrl.scanUrlResult.major,
      ...this.props.currentUrl.scanUrlResult.minor,
    ];
    disableArray.forEach((ele) => {
      let data = {
        access_token: this.props.loggedInUserObj.userDetails.accessToken,
        ruleid: reportData[ele['dataIndex']][8],
      };
      axios
        .post('/api/user/disableRule', data)
        .then((res) => {
          showSnackbar(res.data.snackbar, this);
        })
        .catch((err) => showSnackbarError(err.message, this));
    });
    this.handleSubmit();
  };
  handleBugRaise = (selectedArray) => {
    this.setState({ loading: false });
    const { userDetails } = this.props.currentUser;
    const reportData = [
      ...this.props.currentUrl.scanUrlResult.critical,
      ...this.props.currentUrl.scanUrlResult.major,
      ...this.props.currentUrl.scanUrlResult.minor,
    ];
    let jiraData = [];
    let violationIds = [];
    selectedArray.forEach((ele) => {
      let eachRow = reportData[ele['dataIndex']];
      violationIds.push(eachRow);
      jiraData.push({
        fields: {
          summary: `${eachRow[0]} - ${eachRow[7]} - ${eachRow[1]} - [Accessibility Issue]`,
          description: `Issue Description: ${eachRow[3]}. \n \n Remediation Suggestion: ${eachRow[4]}. \n \n Rule Name: ${eachRow[2]}. \n \n Element: ${eachRow[6]}`,
          issuetype: {
            name: 'Bug',
          },
          labels: [`${eachRow[7]}`, `${eachRow[1]}`],
        },
      });
    });

    let data = {
      access_token: userDetails.accessToken,
      userId: userDetails.userId,
      postData: { issueUpdates: jiraData },
      violationIds,
      role: userDetails.role,
    };
    axios
      .post('/api/user/bugRaise', data)
      .then((res) => {
        showSnackbar(res.data.snackbar, this, 5000);
      })
      .catch((err) => showSnackbarError(err.message, this));
  };
  handleRemoveViolation = (vioArray) => {
    const reportData = [
      ...this.props.currentUrl.scanUrlResult.critical,
      ...this.props.currentUrl.scanUrlResult.major,
      ...this.props.currentUrl.scanUrlResult.minor,
    ];
    vioArray.forEach((ele) => {
      let rowData = reportData[ele['dataIndex']];
      let data = {
        url: rowData[0],
        element: rowData[6],
        path: rowData[5],
        ruleId: rowData[8],
        ruleName: rowData[2],
        ruleDescription: rowData[3],
        remediationSuggestion: rowData[4],
        impactLevel: rowData[7],
        access_token: this.props.loggedInUserObj.userDetails.accessToken,
      };
      axios
        .post('/api/user/RemoveViolation', data)
        .then((res) => {
          showSnackbar(res.data.snackbar, this);
        })
        .catch((err) => showSnackbarError(err.message, this));
    });
    this.handleSubmit();
  };
  autoScrollToTable = () => {
    window.scrollTo(0, this.tableRef.offsetTop - 60);
  };
  updateFilterList = (changeUrl, data, filterList) => {
    changeUrl({ ...data, filterList });
  };
  render() {
    const { classes, currentUrl, t, currentUser } = this.props;
    var data = currentUrl.scanUrlResult;
    const scannedOn = data.scannedOn;
    const {
      reportHeading,
      isFromHistory,
      downloading,
      excelDownloading,
    } = this.state;
    const allowUserReportTable = allowUser(
      currentUser,
      'DASHBOARD_REPORT_TABLE'
    );
    let downloadpdficon = {
      label: 'Download PDF Report',
      callback: this.handleReport,
      icon: PictureAsPdf,
    };
    let downloadExcelicon = {
      label: 'Download Excel Report',
      callback: this.handleExcelReport,
      icon: PictureAsExcel,
    };
    if (downloading) {
      downloadpdficon = {
        label: 'Downloading PDF',
        spinner: true,
        //icon: <ClipLoader sizeUnit={"px"} size={20} color={'black'} style={{ marginTop: 15 }} loading={true} />
        icon: (
          <CircularProgress
            size={20}
            color={'black'}
            style={{ marginTop: 15 }}
          />
        ),
      };
    }
    if (excelDownloading) {
      downloadExcelicon = {
        label: 'Downloading Excel',
        spinner: true,
        //icon: <ClipLoader sizeUnit={"px"} size={20} color={'black'} style={{ marginTop: 15 }} loading={true} />
        icon: (
          <CircularProgress
            size={20}
            color={'black'}
            style={{ marginTop: 15 }}
          />
        ),
      };
    }

    const reportAction = [
      //downloadpdficon,
      downloadExcelicon,
      // { label: "Send Mail", callback: this.handleMail, icon: Email },
      // { label: "View PDF Report", callback: this.handleViewReport, icon: PageView }
    ];
    const columns = [
      {
        name: 'Url',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let element = encodeURIComponent(tableMeta.rowData[5]);
            let url = encodeURIComponent(value);
            return (
              <span>
                {value}
                <br />
                <div style={{ paddingTop: 10 }}>
                  <a href={value} target="_blank" style={{ paddingRight: 5 }}>
                    <OpenInNewIcon fontSize="small" color="action" />
                  </a>
                  <a
                    href={`/highlight-element?url=${url}&element=${element}`}
                    target="_blank"
                    style={{ paddingRight: 5 }}
                  >
                    <MyLocationIcon fontSize="small" color="action" />
                  </a>
                </div>
              </span>
            );
          },
        },
      },
      {
        name: 'Severity',
        options: {
          filter: true,
          filterList: data.filterList ? data.filterList[1] : null,
        },
      },
      {
        name: 'Rule Name',
        options: {
          filter: true,
          filterList: data.filterList ? data.filterList[2] : null,
        },
      },
      {
        name: 'Issue Description',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: value }}
              ></div>
            );
          },
        },
      },
      {
        name: 'Remediation Suggestion',
        options: {
          filter: false,
        },
      },
      {
        name: 'Source',
        options: {
          filter: false,
        },
      },
      {
        name: 'Element',
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        },
      },
      {
        name: 'Impact Level',
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        },
      },
      {
        name: 'Rule ID',
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        },
      },
      {
        name: 'Tags',
        options: {
          display: false,
          filterList: data.filterList ? data.filterList[9] : null,
          filter: true,
        },
      },
      {
        name: 'Sn',
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        },
      },
      {
        name: 'Level',
        options: {
          display: false,
          filterList: data.filterList ? data.filterList[11] : null,
          filter: true,
        },
      },
    ];
    var info = () => {
      if (data.pagesCount) {
        return (
          <>
            <Grid container>
              {reportHeading && data.isSuccess ? (
                <Grid item style={{ paddingTop: 20 }} align="left">
                  <div className={classes.name}>
                    {reportHeading}{' '}
                    {scannedOn && !isFromHistory ? (
                      <Moment fromNow>{scannedOn}</Moment>
                    ) : (
                      ''
                    )}
                  </div>
                  <br />
                </Grid>
              ) : (
                ''
              )}
              <Grid item>
                <div className={classes.root}>
                  <Chip
                    label={
                      'Scan completed in ' + data.exectime / 1000 + ' seconds'
                    }
                    icon={<AccessTime />}
                    className={classes.chip}
                  />
                  <Chip
                    label={'Found ' + data.totalviolations + ' violations'}
                    icon={<ErrorOutline />}
                    className={classes.chip}
                  />
                  <Chip
                    label={'Number of pages scanned ' + data.pagesCount}
                    icon={<DoneAll />}
                    className={classes.chip}
                  />
                  {data.isSuccess && (
                    <>
                      <Chip
                        label={
                          'Number of pages with violation ' +
                          (data.pagesWithViolations
                            ? data.pagesWithViolations
                            : 0)
                        }
                        icon={<HighlightOff />}
                        className={classes.chip}
                      />
                      <Chip
                        label={
                          'Number of pages without violation ' +
                          (data.pagesWithoutViolations
                            ? data.pagesWithoutViolations
                            : 0)
                        }
                        icon={<DoneIcon />}
                        className={classes.chip}
                      />
                      <Chip
                        label={
                          'Percentage of pages without violation ' +
                          (data.perctWithoutViolations
                            ? data.perctWithoutViolations
                            : 0) +
                          ' %'
                        }
                        icon={<VerifiedUser />}
                        className={classes.chip}
                      />
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </>
        );
      }
    };
    var charts = () => {
      if (data.options && data.options2) {
        return (
          <React.Fragment>
            <Grid container>
              <Grid item xs={12}>
                <ViolationCharts
                  data={{
                    score: Math.round(data.score),
                    total: data.totalviolations,
                    major: data.major.length,
                    minor: data.minor.length,
                    critical: data.critical.length,
                  }}
                />
              </Grid>
            </Grid>

            {/* <Grid container spacing={10}>
                            <Grid item sm={6} style={{ cursor: "pointer" }}>
                                <Highcharts options={{
                                    ...data.options,
                                    series: [{ ...data.options.series[0], showInLegend: false }],
                                    title: { text: 'VIOLATIONS BY RULE' },
                                    chart: { type: this.state.type ? this.state.type : 'pie' },
                                    credits: {
                                        enabled: false
                                    },
                                    plotOptions: {
                                        series: {
                                            point: {
                                                events: {
                                                    click: (event) => {
                                                        if (allowUserReportTable) {
                                                            this.updateFilterList(this.props.currentUrl.changeUrl, data, [[], [], [event.point.name], [], [], []])
                                                            this.autoScrollToTable()
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }} />
                            </Grid>
                            <Grid item sm={6} style={{ cursor: "pointer" }}>
                                <Highcharts options={{
                                    ...data.options2,
                                    title: { text: 'VIOLATIONS BY SEVERITY' },
                                    series: [{ ...data.options2.series[0], showInLegend: false }],
                                    chart: { type: this.state.type ? this.state.type : 'pie' },
                                    credits: {
                                        enabled: false
                                    },
                                    plotOptions: {
                                        series: {
                                            point: {
                                                events: {
                                                    click: (event) => {
                                                        if (allowUserReportTable) {
                                                            this.updateFilterList(this.props.currentUrl.changeUrl, data, [[], [event.point.name], [], [], [], []])
                                                            this.autoScrollToTable()
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }} />
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Grid container justify="center">
                            <Grid item >
                                <RadioGroup
                                    row
                                    name="type"
                                    className={classes.group}
                                    value={this.state.type}
                                    onChange={this.handleType}
                                >
                                    <FormControlLabel value="pie" control={<Radio />} label="Pie" />
                                    <FormControlLabel value="column" control={<Radio />} label="Column" />
                                </RadioGroup>
                            </Grid>
                        </Grid> */}
            {allowUserReportTable ? (
              <>
                {console.log(data, data.filterList, 'data')}
                <Grid container spacing={10}>
                  <Grid item sm>
                    <a
                      href="#critical"
                      onClick={() => {
                        this.updateFilterList(
                          this.props.currentUrl.changeUrl,
                          data,
                          [[], ['Critical'], [], [], [], []]
                        );
                        this.autoScrollToTable();
                      }}
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                    >
                      <p className={classes.p}>
                        Critical ({data.critical.length})
                      </p>
                    </a>
                  </Grid>
                  <Grid item sm>
                    <a
                      href="#major"
                      onClick={() => {
                        this.setState(this.state);
                        this.updateFilterList(
                          this.props.currentUrl.changeUrl,
                          data,
                          [[], ['Major'], [], [], [], []]
                        );
                        this.autoScrollToTable();
                      }}
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                    >
                      <p className={classes.p}>Major ({data.major.length})</p>
                    </a>
                  </Grid>
                  <Grid item sm>
                    <a
                      href="#minor"
                      onClick={() => {
                        this.setState(this.state);
                        this.updateFilterList(
                          this.props.currentUrl.changeUrl,
                          data,
                          [[], ['Minor'], [], [], [], []]
                        );
                        this.autoScrollToTable();
                      }}
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                    >
                      <p className={classes.p}>Minor ({data.minor.length})</p>
                    </a>
                  </Grid>
                </Grid>
                <section ref={(tableRef) => (this.tableRef = tableRef)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <DataTable
                        key={
                          (data && data.filterList
                            ? data.filterList.toString()
                            : 0) + downloading
                        }
                        title="REPORT"
                        columns={columns}
                        data={[
                          ...this.props.currentUrl.scanUrlResult.critical,
                          ...this.props.currentUrl.scanUrlResult.major,
                          ...this.props.currentUrl.scanUrlResult.minor,
                        ]}
                        expandableRows={true}
                        selectableRows={true}
                        renderExpandableRow={(rowData, rowMeta) =>
                          this.renderExpandableRow(
                            [
                              ...this.props.currentUrl.scanUrlResult.critical,
                              ...this.props.currentUrl.scanUrlResult.major,
                              ...this.props.currentUrl.scanUrlResult.minor,
                            ],
                            rowMeta.dataIndex,
                            rowData
                          )
                        }
                        download={true}
                        filter={true}
                        toolBarAction={reportAction}
                        onFilterChange={this.onFilterChange}
                        customToolbarSelect={(
                          selectedRows,
                          displayData,
                          setSelectedRows
                        ) => (
                          <CustomToolbarSelect
                            actionList={[
                              {
                                title: 'IGNORE RULE',
                                eventCallback: this.handleDisableRule,
                                icon: Flag,
                                type: 'button',
                                alertTitle:
                                  'Do you want to report and disable selected Rule(s) ?',
                              },
                              {
                                title: 'IGNORE ROW',
                                eventCallback: this.handleRemoveViolation,
                                alertTitle:
                                  'Do you want to hide selected violation(s) ?',
                                icon: Warning,
                                type: 'button',
                              },
                              {
                                title: 'REPORT ON JIRA',
                                eventCallback: this.handleBugRaise,
                                alertTitle:
                                  'Do you want to Create Issue on Jira for selected violation(s)?',
                                icon: BugReport,
                                type: 'button',
                              },
                            ]}
                            selectedRows={selectedRows}
                            displayData={displayData}
                            setSelectedRows={setSelectedRows}
                          />
                        )}
                        rowsPerPage={20}
                      />
                    </Grid>
                  </Grid>
                </section>
              </>
            ) : null}
          </React.Fragment>
        );
      }
    };

    return (
      <React.Fragment>
        <main className={classes.layout1}>
          <Grid container>
            <Grid item style={{ paddingTop: 0 }} align="left">
              <div className={classes.name}>
                {t('Dashboard.Title_Hello')},{' '}
                {this.props.loggedInUserObj.userDetails.firstName}
              </div>
              <br />
            </Grid>
          </Grid>
          <ValidatorForm
            className={classes.root}
            onSubmit={() =>
              this.setState(
                { isFromHistory: false, scannedOn: new Date() },
                () => this.handleSubmit()
              )
            }
            onError={() => showSnackbarError('Invalid URL', this)}
          >
            <Grid container>
              <Grid item sm={4} style={{ paddingLeft: 10 }}>
                <TextValidator
                  label="Website Address"
                  id="url"
                  tabIndex={100}
                  name="url"
                  value={this.state.url}
                  onChange={this.handleChange}
                  margin="normal"
                  fullWidth
                  validators={['required', 'isUrl']}
                  errorMessages={['']}
                  InputLabelProps={{
                    className: classes.label,
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={4} style={{ paddingTop: 50 }}>
                <Tooltip title="Scan Settings">
                  <IconButton
                    aria-label="settings"
                    className={classes.margin}
                    onClick={() =>
                      this.setState({
                        enableSettingsSection: !this.state
                          .enableSettingsSection,
                      })
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                <Button
                  type="submit"
                  value="submit"
                  tabIndex={101}
                  disabled={this.state.disableCall}
                  className={classes.button}
                >
                  Scan for errors
                  <SendIcon className={classes.rightIcon} />
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
          {allowUserReportTable && (
            <>
              <Grid container>
                {this.state.enableSettingsSection && (
                  <Grid item style={{ paddingTop: 10 }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <InputLabel className={classes.options}>
                        {' '}
                        Run With Driver:{' '}
                      </InputLabel>
                      <InputLabel>Off </InputLabel>
                      <FormControlLabel
                        className={classes.formcontrollabel}
                        tabIndex={102}
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.driver}
                            onChange={(event) =>
                              this.handleChangeSwitch(event, 'driver')
                            }
                          />
                        }
                        label="On"
                      />
                      <Tooltip
                        disableTouchListener
                        title="To scan websites which are Javascript enabled"
                      >
                        <span style={{ position: 'relative', top: '15%' }}>
                          <HelpOutline />
                        </span>
                      </Tooltip>
                    </div>
                  </Grid>
                )}

                <Grid item sm={1}></Grid>
                {this.state.enableSettingsSection && (
                  <Grid item style={{ paddingTop: 10 }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <InputLabel className={classes.options}>
                        {' '}
                        Shallow Scanning:{' '}
                      </InputLabel>
                      <InputLabel>Off </InputLabel>
                      <FormControlLabel
                        className={classes.formcontrollabel}
                        tabIndex={103}
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.shallowScan}
                            onChange={(event) =>
                              this.handleChangeSwitch(event, 'shallowScan')
                            }
                          />
                        }
                        label="On"
                      />
                      <Tooltip
                        className={classes.tooltip}
                        disableTouchListener
                        title="To scan only mentioned url "
                      >
                        <span style={{ position: 'relative', top: '15%' }}>
                          <HelpOutline />
                        </span>
                      </Tooltip>
                    </div>
                  </Grid>
                )}
              </Grid>
              <Grid container>
                {this.state.enableSettingsSection && (
                  <Grid item style={{ paddingTop: 10 }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <InputLabel className={classes.options}>
                        {' '}
                        {'Scan <img> alt attribute:'}{' '}
                      </InputLabel>
                      <InputLabel>Off </InputLabel>
                      <FormControlLabel
                        className={classes.formcontrollabel}
                        tabIndex={102}
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.visionAi}
                            onChange={(event) =>
                              this.handleChangeSwitch(event, 'visionAi')
                            }
                          />
                        }
                        label="On"
                      />
                      <Tooltip
                        disableTouchListener
                        title="To scan image description alt text is appropriate"
                      >
                        <span style={{ position: 'relative', top: '15%' }}>
                          <HelpOutline />
                        </span>
                      </Tooltip>
                    </div>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          <Grid container>
            <Grid item sm={4}>
              <div style={{ paddingLeft: 35 }}>
                {this.state.loading ? (
                  <div>
                    {this.state.isFromHistory
                      ? 'Fetching Data'
                      : !this.state.shallowScan && data.isSuccess == undefined
                      ? 'Crawling...'
                      : 'Scanning....'}
                    <LinearProgress className={classes.scancolor} />
                  </div>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              {this.state.message && (
                <Chip
                  label={this.state.message}
                  icon={<DoneIcon />}
                  className={classes.chip}
                />
              )}
            </Grid>
          </Grid>
          {info()}
          <br />
          {charts()}
        </main>
      </React.Fragment>
    );
  }
}
Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(
  withStyles(styles)(
    AuthenticatedPage(WithDashboardScan(withSnackbar(Dashboard)))
  )
);
