import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Box, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Icon from '@material-ui/core/Icon';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import axios from 'axios'
import LinkOffIcon from '@material-ui/icons/LinkOff';

import { Profile, SidebarNav, /*UpgradePlan*/ } from './components';

import { List, ListItem, Button, colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	drawer: {
		width: 240,
		[theme.breakpoints.up('lg')]: {
			marginTop: 64,
			height: 'calc(100% - 64px)'
		}
	},
	root: {
		backgroundColor: theme.palette.white,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1)
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	nav: {
		marginBottom: theme.spacing(2)
	},
	item: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0
	},
	button: {
		color: colors.blueGrey[800],
		padding: '10px 8px',
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		fontWeight: theme.typography.fontWeightMedium
	},
	icon: {
		color: theme.palette.icon,
		width: 24,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(1)
	},
	active: {
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightMedium,
		'& $icon': {
			color: theme.palette.primary.main
		}
	}
}));

const Sidebar = props => {
	const { open, variant, onClose, className, ...rest } = props;

	const classes = useStyles();

	let handleCloseApi = () => {
		axios.get('/api/user/getDomine').then((res) =>
			window.open(`${res.data.domine}/jsondoc-ui.html?url=${res.data.domine}/jsondoc`, "_blank")
		);
	};

	const pages = [
		{
			title: 'Dashboard',
			href: '/home/dashboard',
			icon: <Icon>dashboard</Icon>
		},
		{
			title: 'Rules',
			href: '/home/rules',
			icon: <Icon>tune</Icon>
		},
		{
			title: 'Schedule Scans',
			href: '/home/schedule',
			icon: <Icon>timer</Icon>
		},

		{
			title: 'History',
			href: '/home/history',
			icon: <Icon>update</Icon>
		},

		{
			title: 'Plugins',
			href: '/home/plugins',
			icon: <Icon>settingsinput</Icon>
		},
		{
			title: 'API',
			onClick: handleCloseApi,
			href: 'https://api.accessbot.io/jsondoc-ui.html?url=https://api.accessbot.io/jsondoc',
			icon: <Icon>code</Icon>
		}
	];

	const aiPages = [
		{
			title: 'Image Labels',
			href: '/home/image-labels',
			icon: <Icon>image</Icon>
		},
		{
			title: 'Image Text',
			href: '/home/image-text',
			icon: <Icon>image_search</Icon>
		},
	]

	const seoPages = [
		{
			title: 'SEO Dashboard',
			href: '/home/seo-dashboard',
			icon: <Icon>dashboard</Icon>
		},
		{
			title: 'Broken Links',
			href: '/home/broken-links',
			icon: <Icon>link</Icon> // <LinkOffIcon />
		},
	];

	const accountPages = [
		{
			title: 'My Account',
			href: '/home/profile',
			icon: <AccountBoxIcon />
		},
		{
			title: 'Settings',
			href: '/home/settings',
			icon: <SettingsIcon />
		},
	];

	return (
		<Drawer
			anchor="left"
			classes={{ paper: classes.drawer }}
			onClose={onClose}
			open={open}
			variant={variant}
		>
			<div
				{...rest}
				className={clsx(classes.root, className)}
			>
				<Profile />
				<Divider className={classes.divider} />

				<List
					{...rest}
					className={clsx(classes.root, className)}
				>
					{/** Accessibility */}
					<ListItem
						className={classes.item}
						disableGutters
						key={'Accessibility'}
					>
						<Button
							style={{ padding: 0 }}
							activeClassName={classes.active}
							className={classes.button}
						//component={CustomRouterLink}
						//to={page.href}
						>
							<Typography variant="body1" gutterBottom style={{ fontSize: '1.2rem' }} >{'Accessibility'}  </Typography>
						</Button>
					</ListItem>
					<Box style={{ paddingLeft: 12 }}>
						<SidebarNav
							className={classes.nav}
							pages={pages}
						/>
					</Box>

					{/** AI */}
					<ListItem
						className={classes.item}
						disableGutters
						key={'AI'}
					>
						<Button
							style={{ padding: 0 }}
							activeClassName={classes.active}
							className={classes.button}
						//component={CustomRouterLink}
						//to={page.href}
						>
							<Typography variant="body1" gutterBottom style={{ fontSize: '1.2rem' }} >{'AI'}  </Typography>
						</Button>
					</ListItem>
					<Box style={{ paddingLeft: 12 }}>
						<SidebarNav
							className={classes.nav}
							pages={aiPages}
						/>
					</Box>

					{/** SEO */}
					<ListItem
						className={classes.item}
						disableGutters
						key={'SEO'}
					>
						<Button
							style={{ padding: 0 }}
							activeClassName={classes.active}
							className={classes.button}
						//component={CustomRouterLink}
						//to={page.href}
						>
							<Typography variant="body1" gutterBottom style={{ fontSize: '1.2rem' }} >{'SEO'}  </Typography>
						</Button>
					</ListItem>
					<Box style={{ paddingLeft: 12 }}>
						<SidebarNav
							className={classes.nav}
							pages={seoPages}
						/>
					</Box>

					{/** Account */}
					<ListItem
						className={classes.item}
						disableGutters
						key={'Account'}
					>
						<Button
							style={{ padding: 0 }}
							activeClassName={classes.active}
							className={classes.button}
						//component={CustomRouterLink}
						//to={page.href}
						>
							<Typography variant="body1" gutterBottom style={{ fontSize: '1.2rem' }} >{'Account'}  </Typography>
						</Button>
					</ListItem>

					<Box style={{ paddingLeft: 12 }}>
						<SidebarNav
							className={classes.nav}
							pages={accountPages}
						/>
					</Box>

				</List>



				{/* <UpgradePlan /> */}
			</div>
		</Drawer>
	);
};

Sidebar.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
	variant: PropTypes.string.isRequired
};

export default Sidebar;
