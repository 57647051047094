import React, { Component, Suspense } from 'react';
import './App.css';
import { Switch, Route, Redirect, BrowserRouter as Router } from "react-router-dom";
import UserAccount from './components/UserAccount';
import Login from './components/Login';
// import Login from './Auth/Login';
import CssBaseline from '@material-ui/core/CssBaseline';
import { defaultUserObj, UserLoggedInContextProvider } from "./LoggedInContext"
import Register from './components/Register'
import Home from './components/Home'
import ForgotPassword from './components/ForgotPassword';
import Spinner from '@material-ui/core/CircularProgress';
import i18n from './i18nTranslation';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Callback from './Auth/Callback';
import { updateTheme } from './CalculateTheme';
import AccountConfirmation from '../src/components/AccountConfirmation';
import Resetpassword from './components/Resetpassword';
import ResetpasswordNoAuth from './components/ResetpasswordNoAuth';
import ScanPage from './components/ScanPageDemo';

class App extends Component {
  constructor(props) {
    super(props);
    let lsUserAuth = localStorage.getItem('UserAuth');
    try {
      lsUserAuth = JSON.parse(lsUserAuth);
    }
    catch (error) {
      lsUserAuth = null;
    }
    this.state = {
      userDetails: lsUserAuth ? lsUserAuth : defaultUserObj,
      changeUser: this.changeUser,
      updateSize: this.updateSize,
      size: 14
    }
  };

  changeUser = (userObj) => {
    if (userObj) {
      localStorage.setItem('UserAuth', JSON.stringify(userObj));
      this.setState({ userDetails: userObj });
    }
    else {
      localStorage.setItem('UserAuth', JSON.stringify(defaultUserObj));
      this.setState({ userDetails: defaultUserObj });
    }
  };

  updateSize = (size) => {
    this.setState({ size })
  }

  render() {
    return (
      <React.Fragment>
        <MuiThemeProvider theme={createMuiTheme(updateTheme(this.state.size))}>
          <CssBaseline />
          <UserLoggedInContextProvider value={this.state}>
            <Suspense fallback={<Spinner style={{ position: "absolute", top: "50%", left: "50%" }} />}>
              <Router basename="">
                <div className="App">
                  <Switch>
                    <Route path='/reset-password' component={ResetpasswordNoAuth} />
                    <Route path='/index' component={Home} />
                    <Route path='/login' component={Login} />
                    <Route path='/callback' component={Callback} />
                    <Route path='/register' component={Register} />
                    <Route path='/forgotpassword' component={ForgotPassword} />
                    <Route path='/home' component={UserAccount} />
                    <Route path='/login.html' component={AccountConfirmation} />
                    <Route path='/forgotpassword.html' render={(props) => <ForgotPassword {...props} resetPass={true} />} />
                    <Route path='/scan' component={ScanPage} />
                    <Redirect to='/login' />
                  </Switch>
                </div>
              </Router>
            </Suspense>
          </UserLoggedInContextProvider>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
