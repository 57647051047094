import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import logo from '../assets/WhiteLogo.png'
import SendIcon from '@material-ui/icons/Send';
import Footer from './Footer'
import CssBaseline from '@material-ui/core/CssBaseline';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { showSnackbar } from '../common/Utils';
import queryString from 'query-string';

const styles = theme => ({

    menuButton: {
        marginLeft: theme.spacing.unit * 22,
        height: 46,
        display: "flex",
        [theme.breakpoints.down(600 + theme.spacing.unit * 3 * 2)]: {
            marginLeft: 0,
        },
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    bdyMinHght: {
        minHeight: "400px",
        marginBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 8
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2196F3'
    },
    toolbarTitle: {
        flex: 1,
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    navlink: {
        textTransform: theme.navbar.textTransform,
        fontWeight: 500,
        textDecoration: 'none',
        color: theme.navbar.color,
        fontSize: theme.typography.pxToRem(20),
        marginRight: theme.spacing.unit * 22,
        [theme.breakpoints.down(600 + theme.spacing.unit * 3 * 2)]: {
            marginRight: 0,
        },
    },
    title: {
        paddingLeft: theme.title.paddingLeft,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: theme.button.width,
        padding: theme.button.padding,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
    error: {
        color: theme.error.color
    },
});

class ForgotPassword extends React.Component {

    state = {
        email: '',
        newPassword: '',
        repeatPassword: '',
        resetPass: this.props.resetPass || false,
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('ispass', (value) => {
            if (value.length < 8) {
                return false;
            }
            return true;
        });
        const { ref } = queryString.parse(this.props.location.search);
        if (!ref && this.props.resetPass)
            this.props.history.push('/login');
    }

    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    handleSubmit = () => {
        let data = {
            email: this.state.email
        }
        axios.get(`/api/user/reset-password/${this.state.email}/`).then((res) => {
            if (res.data.status === 'failed'){
                showSnackbar({
                    message: res.data.message,
                    variant: 'error'
                }, this)
                //showSnackbar(res.data.snackbar, this)
            } else {
                showSnackbar({
                    message: 'Please check your inbox',
                    variant: 'success'
                }, this)
            }
        })
    }

    handleUpdate = () => {
        const { ref } = queryString.parse(this.props.location.search);
        let data = {
            requestId: ref.replace("forgotpassword_", ""),
            requestContent: this.state.newPassword,
            requestType: "resetpassword"
        }
        axios.post('/api/user/updatePassword', data).then((res) => {
            showSnackbar(res.data.snackbar, this);
            this.props.history.push('/login');
        })
    }

    render() {
        const { classes } = this.props;
        return (

            <React.Fragment>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                            <img src={logo} className={classes.menuButton} alt="logo" />
                        </Typography>
                        <Link tabIndex={0} to="/login" className={classes.navlink} >Login</Link>
                    </Toolbar>
                </AppBar>
                <main className={classes.layout}>
                    {this.state.resetPass ?
                        <Grid container>
                            <Grid item sm={12} style={{ paddingTop: 70 }} align="left">
                                <div className={classes.title} >Update Password</div><br />
                                <br /><br />

                                <ValidatorForm onSubmit={this.handleUpdate}>
                                    <Grid container spacing={8} alignItems="flex-end">

                                        <Grid item sm={6}>
                                            <TextValidator
                                                label="New Password"
                                                tabIndex={101}
                                                name="newPassword"
                                                id="newPassword"
                                                onChange={this.handleChange}
                                                validators={['required', 'ispass']}
                                                errorMessages={['This field is required', 'Password should contain minimum 8 character']}
                                                value={this.state.newPassword}
                                                type="password"
                                                fullWidth
                                                InputLabelProps={{
                                                    className: classes.label,
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br /><br />
                                    <Grid container spacing={8} alignItems="flex-end">

                                        <Grid item sm={6}>
                                            <TextValidator
                                                label="Repeat Password"
                                                name="repeatPassword"
                                                id="repeatPassword"
                                                tabIndex={102}
                                                onChange={this.handleChange}
                                                value={this.state.repeatPassword}
                                                type="password"
                                                validators={['required', `isStringEqual:${this.state.newPassword}`]}
                                                errorMessages={['This field is required', 'New Password, Repeat Password do not match']}
                                                fullWidth
                                                required
                                                InputLabelProps={{
                                                    className: classes.label,
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <p className={classes.error} >{this.state.userError}</p>
                                    <br /><br />

                                    <Grid container>
                                        <Grid item>
                                            <Button value='submit' type="submit" variant="contained" tabIndex={101} className={classes.button}>
                                                UPDATE
                                            <SendIcon className={classes.rightIcon} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </Grid>
                            <Grid item sm={1}></Grid>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item sm={12} style={{ paddingTop: 70 }} align="left">
                                <div className={classes.title} >Forgot Password.</div><br />

                                <ValidatorForm onSubmit={this.handleSubmit}>
                                    <Grid container spacing={8} alignItems="flex-end">

                                        <Grid item sm={5}>
                                            <TextValidator
                                                label="Email"
                                                tabIndex={100}
                                                id="email"
                                                onChange={this.handleChange}
                                                name="email"
                                                value={this.state.email}
                                                withRequiredValidator
                                                validators={['required', 'isEmail']}
                                                errorMessages={['this field is required', 'email is not valid']}
                                                fullWidth
                                                required
                                                InputLabelProps={{
                                                    className: classes.label,
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                    <p className={classes.error} >{this.state.userError}</p>
                                    <br /><br />

                                    <Grid container>
                                        <Grid item>
                                            <Button value='submit' type="submit" variant="contained" tabIndex={101} className={classes.button}>
                                                SUBMIT
                                                <SendIcon className={classes.rightIcon} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </Grid>
                            <Grid item sm={1}></Grid>
                        </Grid>
                    }
                </main>
                <Footer />
            </React.Fragment>

        );
    }
}
export default withStyles(styles)(withSnackbar(ForgotPassword));
