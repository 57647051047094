import axios from 'axios'

export const showSnackbar = (snackbar, that, autoHideDuration = 3000) => {
    if (snackbar.message && snackbar.variant)
        that.props.enqueueSnackbar(snackbar.message, { variant: snackbar.variant, autoHideDuration: autoHideDuration, preventDuplicate: true })
}

export const showSnackbarError = (error, that, autoHideDuration = 3000) => {
    that.props.enqueueSnackbar((error ? error : "Something went wrong"), { variant: "error", autoHideDuration: autoHideDuration, preventDuplicate: true })
}

export const allowUser = (user, section) => {
    const { roles } = user.userDetails;
    if (!roles) {
        getUserDetails(user)
    }
    if (roles && roles.includes(section))
        return true
    else return false
}

const getUserDetails = (crruser) => {
    if (!getUserDetails.initiated) {
        getUserDetails.initiated = true
        const user = crruser.userDetails
        axios.post('/api/user/getUser', { userId: user.userId, accessToken: user.accessToken }).then(res =>
            crruser.changeUser({
                ...user,
                role: res.data.user.role,
                roles: res.data.user.roles,
            }))
    }
}