const defaultTheme = {
    palette:{
        // contrastThreshold:22,
        // type:"dark"
    },
    typography: {
        fontSize: 14,
        useNextVariants: true,
    },
    title: {
        paddingTop: 20,
        paddingLeft: 10,
        color: '#2196F3',
        fontSize: '2.92rem',
        lineHeight: '110%',
    },
    name: {
        color: '#2196F3',
        lineHeight: '110%'
    },
    options: {
        color: '#2196F3',
        lineHeight: '110%'
    },
    label: {
        position: 'relative',
        textAlign: 'left'
    },
    navbar: {
        color: "#fff",
        textTransform: "none",
    },
    bgClr: {
        backgroundColor: '#2196F3',
    },
    button: {
        backgroundColor: "#0d5087",
        fontWeight: "600",
        color: "white",
        width: 156,
        padding: 5,
    },
    error: {
        color: 'red'
    },
    p: {
        color: '#2bbbad',
        fontWeight: 500
    },

    muiTableAction: {
        float: "left",
    },
    overrides: {
        MUIDataTableSelectCell: {
            fixedHeader: {
                position: 'relative'
            },
            headerCell: {
                zIndex: 0,
            }
        },
        MUIDataTableToolbar: {
            root: {
                textAlign: 'left',
                overflow: 'auto',
            },
        },
        MuiTableCell: {
            root: {
                padding: 5,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                maxWidth: window.innerWidth < 500 ? 240 : 190,
                minWidth: '90%',
            }
        },
        MuiSnackbar: {
            root: {
                top: 70
            }
        }
    }
}

export function updateTheme(size) {
    const typography = { ...defaultTheme.typography, fontSize: size }
    return { ...defaultTheme, typography }
}