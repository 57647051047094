import React from "react";

const dashboard = {
    url: '',
    options: '',
    options2: '',
    critical: [],
    major: [],
    minor: [],
    exectime: '',
    pagesCount: '',
    totalviolations: '',
    driver: false,
    type:'pie'
};

const UserDashboardContext = React.createContext({
    scanUrlResult: dashboard,
    changeUrl: () => {

    }
}
);

const UserDashboardContextProvider = UserDashboardContext.Provider;
const UserDashboardContextConsumer = UserDashboardContext.Consumer;
export { UserDashboardContext as default, dashboard, UserDashboardContextProvider, UserDashboardContextConsumer };
