import React, { Component } from 'react';
import axios from 'axios';
import Grid from "@material-ui/core/Grid";
import ViolationCharts from './ViolationCharts';
import DataTable from '../common/DataTable';
import Highcharts from './Highcharts';
import Chip from '@material-ui/core/Chip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import AccessTime from '@material-ui/icons/AccessTime';
import HighlightOff from '@material-ui/icons/HighlightOff';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import DoneAll from '@material-ui/icons/DoneAll';
import Timeline from '@material-ui/icons/Timeline';
import ShowChart from '@material-ui/icons/ShowChart';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import AuthenticatedPage from "../AuthenticatedPage";
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import LinearProgress from '@material-ui/core/LinearProgress';
import CustomToolbarSelect from '../common/CustomToolbarSelect';
import { Tooltip } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Delete from "@material-ui/icons/Delete";
import CompareArrows from "@material-ui/icons/CompareArrows";
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../common/Utils';
import Highstock from '../common/Highstock';
import WithDashboardScan from './withDashboardScan';

const styles = theme => ({
    bdyMinHght: {
        minHeight: "400px",
        marginBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 8
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 6,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginBottom: 100,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    },
    name: {
        color: theme.name.color,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.name.lineHeight
    },
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing.unit * 1,
        paddingBottom: theme.spacing.unit * 1,
    },
    paper: {
        padding: theme.spacing.unit * 1,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    scancolor: {
        color: '#26a69',
    },
    url: {
        width: 80, wordWrap: 'break-word'
    },
    showMoreLink: {
        cursor: 'pointer',
        padding: 10
    },
    faIcon: {
        cursor: 'pointer'
    },
    muiTableAction: theme.muiTableAction,
})

class History extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            compare: [],
            access_token: '',
            type: "pie",
            loading: false,
            fetchingRec: true,
            filterList: [],
        }
        this.filterLength = 0;
    }

    message = () => {
        alert('Failed! History is not available in GUEST PLAN. Upgrade the plan to access the History ')
    }
    componentDidMount() {
        if (this.props.loggedInUserObj.userDetails.plan === "GUEST") {
            setTimeout(this.message, 1000)
        } else {
            let data = {
                access_token: this.props.loggedInUserObj.userDetails.accessToken,
            }
            this.setState({ access_token: this.props.loggedInUserObj.userDetails.accessToken })
            axios.post('/api/user/history', data)
                .then(res => {
                    this.setState({
                        data: res.data.history
                    }, () => {
                        this.setState({ fetchingRec: false })
                        this.setTrendData()
                    })
                })
        }

    }
    setTrendData = () => {
        const histories = this.state.data;
        const hisTrend = {};
        histories.forEach(his => {
            if (hisTrend[his[0]] === undefined) {
                hisTrend[his[0]] = [[parseInt((new Date(his[1]).getTime())), his[3], his[2]]]
            } else {
                hisTrend[his[0]].push([parseInt((new Date(his[1]).getTime())), his[3], his[2]])
            }
        })
        Object.keys(hisTrend).forEach(his => {
            hisTrend[his] = hisTrend[his].sort(function (a, b) { return a[0] - b[0] })
        })
        this.setState({ trendGraphMaster: hisTrend });
    }
    handleShow = (index) => {
        const histories = this.state.data;
        this.props.storeHistory({ reportHeading: `Scanned ${histories[index][0]} on ${histories[index][1]}`, isFromHistory: true, url: histories[index][0] });
        this.props.history.push(`/home/dashboard/${histories[index][2]}`);
    }
    handleDelete = async (delArray) => {
        var historydata = this.state.data;
        var delIds = [];
        delArray.forEach(del => {
            let id = historydata[del['dataIndex']][2];
            delIds.push(id);
        });

        let data = {
            access_token: this.props.loggedInUserObj.userDetails.accessToken,
            ids: delIds
        }

        let resa = await fetch(`/api/user/deletehistory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        let json = await resa.json();
        if (json.staus === 'failed') {
            showSnackbar({ message: json.message, variant: 'error' }, this)
        } else {
            showSnackbar({ message: json.message, variant: 'success' }, this)
            historydata = historydata.filter(fil => !delIds.includes(fil[2]));
            this.setState({ data: historydata, compare: null });
        }
        //axios.post('/api/user/deletehistory', data).then((res) => { showSnackbar(res.data.snackbar, this) }).catch((err) => showSnackbarError(err.message, this))

    }
    handleClickCompare = (allRowsSelected) => {
        // console.log(data)
        if (allRowsSelected.length === 2) {
            const histories = this.state.data;
            if (histories[allRowsSelected[0]['dataIndex']][0].replace("https://", "").replace("http://", "") === histories[allRowsSelected[1]['dataIndex']][0].replace("https://", "").replace("http://", "")) {
                let compare = [];
                allRowsSelected.forEach(row => {
                    compare.push({
                        url: histories[row.dataIndex][0],
                        formatedDate: histories[row.dataIndex][1],
                        id: histories[row.dataIndex][2],
                    })
                })
                this.setState({ compare, loading: true, comparedata: null, comparedata2: null }, async () => {
                    window.scrollTo(0, this.comRef.offsetTop + 10)
                    let data = {
                        access_token: this.props.loggedInUserObj.userDetails.accessToken,
                        url: "history:" + this.state.compare[0].id,
                        // user_agent: navigator.userAgent,
                        // driver: this.state.driver,
                    }
                    let data2 = {
                        access_token: this.props.loggedInUserObj.userDetails.accessToken,
                        url: "history:" + this.state.compare[1].id,
                        // user_agent: navigator.userAgent,
                        // driver: this.state.driver,
                    }
                    let result = await axios.all([
                        axios.post('/api/user/scansite', data),
                        axios.post('/api/user/scansite', data2),
                    ])
                    this.setState({ loading: false })
                    this.setDataForCompare("comparedata", result[0], this.state.compare[1].id)
                    this.setDataForCompare("comparedata2", result[1], this.state.compare[1].id)
                })
            } else {
                showSnackbarError("Select two same websites to compare", this);
            }
        } else if (allRowsSelected.length === 1) {
            showSnackbarError("Select two same websites to compare", this);
        } else if (allRowsSelected.length > 2) {
            showSnackbarError("Not allowed to compare more than two websites", this);
        }
    }

    setDataForCompare = (target, res, id) => {
        if (res.data.violations !== 0) {
            this.setState({
                [target]: {
                    error: '',
                    type: 'pie'
                }
            })
            this.setState(
                {
                    [target]: {
                        id: id,
                        url: this.state.url,
                        score:res.data.score,
                        options: {
                            series: [{
                                data: res.data.violate,
                                name: '',
                                showInLegend: false
                            }],
                            xAxis: [{ categories: res.data.violations[0] }],
                            chart: { type: this.state.type },
                            title: { text: 'VIOLATIONS BY RULE' },
                            categories: res.data.violations[0],
                            yAxis: {
                                min: 0,
                                title: {
                                    text: ''
                                }
                            },
                        },
                        options2: {
                            series: [{
                                data: [{
                                    name: 'Critical',
                                    y: res.data.critical.length,
                                    color: "red",
                                }, {
                                    name: 'Major',
                                    y: res.data.major.length,
                                    color: "brown",
                                }, {
                                    name: 'Minor',
                                    y: res.data.minor.length,
                                    color: "yellow",
                                }],
                                maxPointWidth: 50,
                                name: '',
                                showInLegend: false
                            }],
                            xAxis: { categories: res.data.severity[0], crosshair: true },
                            yAxis: {
                                min: 0,
                                title: {
                                    text: ''
                                }
                            },
                            chart: { type: this.state.type },
                            title: { text: 'VIOLATIONS BY SEVERITY' },
                        },
                        critical: res.data.critical,
                        major: res.data.major,
                        minor: res.data.minor,
                        exectime: res.data.time,
                        pagesCount: res.data.pagesCount,
                        totalviolations: res.data.totalviolations,
                        pagesWithViolations: res.data.pagesWithViolations,
                        pagesWithoutViolations: res.data.pagesWithoutViolations,
                        perctWithoutViolations: res.data.perctWithoutViolations,
                        // driver: this.state.driver
                    }
                })
        } else {
            this.setState(
                {
                    [target]: { message: "Everything Looks Good! No Violations Found" }
                })
        }
    }

    handleType = (event) => {
        const type = event.target.value;
        const { comparedata, comparedata2 } = this.state;
        this.setState({ type });
        if (comparedata && comparedata2) {
            comparedata['options']['chart'] = { type };
            comparedata['options']['series'] = [{ ...comparedata.options.series[0], showInLegend: false }];
            comparedata['options2']['chart'] = { type };
            comparedata['options2']['series'] = [{ ...comparedata.options2.series[0], showInLegend: false }];

            comparedata2['options']['chart'] = { type };
            comparedata2['options']['series'] = [{ ...comparedata.options.series[0], showInLegend: false }];
            comparedata2['options2']['chart'] = { type };
            comparedata2['options2']['series'] = [{ ...comparedata.options2.series[0], showInLegend: false }];

            this.setState({ comparedata, comparedata2 });
        }
    }

    handleShowMoreDetails = (data, date) => {
        this.props.storeHistory({ ...data, type: this.state.type, reportHeading: "Scanned " + this.state.compare[0].url + " on " + date, isFromHistory: true, url: this.state.compare[0].url });
        this.props.history.push('/home/dashboard');
    }

    charts = (data, date) => {
        const { classes } = this.props;
        if (data) {
            if (data.options && data.options2) {
                return (
                    <React.Fragment key={this.state.type}>
                        <Grid container spacing={24}>
                            <Grid item sm={12}>
                                <Highcharts options={{
                                    ...data.options, credits: {
                                        enabled: false
                                    }, chart: { type: this.state.type },
                                    title: { text: 'VIOLATIONS BY RULE' },
                                }} />
                            </Grid>
                            <Grid item sm={12}>
                                <Highcharts options={{
                                    ...data.options2, credits: {
                                        enabled: false
                                    }, chart: { type: this.state.type },
                                    title: { text: 'VIOLATIONS BY SEVERITY' },
                                }} />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.showMoreLink}>
                            <Link onClick={() => this.handleShowMoreDetails(data, date)}>
                                Show more details
                            </Link>
                        </Grid>
                    </React.Fragment>
                )
            } else {
                return (<Grid container>
                    <Grid item>{data.message && <Chip label={data.message} icon={<DoneIcon />} className={classes.chip} />}</Grid>
                </Grid>)
            }
        }
    }

    compareDataStyle = (date, date2, value, value2, inversion = false) => {
        if (new Date(new Date(date).toUTCString()) > new Date(new Date(date2).toUTCString())) {
            if (value === value2) {
                return null
            } else if (value > value2) {
                return { color: (inversion ? 'red' : 'green') }
            } else if (value < value2) {
                return { color: (inversion ? 'green' : 'red') }
            }
        } else {
            return null;
        }
    }

    showTrend = () => {
        const { filterList, trendGraphMaster } = this.state;
        let trendGraph = {};
        filterList.forEach(fil => trendGraph[fil] = trendGraphMaster[fil])
        this.setState({ trendGraph }, () => {
            setTimeout(() => {
                window.scrollTo(0, this.trendRef.offsetTop - 100)
            }, 10);
        })
    }

    showHisDetailsfromGraph = (website, xValue) => {
        const { trendGraph } = this.state;
        trendGraph[website].forEach((arr) => {
            if (arr[0] === xValue) {
                this.props.history.push(`/home/dashboard/${arr[2]}`);
            }
        })
    }

    onFilterChange = (filterValue, filterList) => {
        this.setState({ filterList: filterList[0] });
    }

    render() {
        const { classes } = this.props;
        const { comparedata, comparedata2, compare, filterList, fetchingRec } = this.state;
        const columns = [
            {
                name: "Website",
                options: {
                    filter: true,
                    filterList: filterList,
                }
            },
            {
                name: "Scanned Date",
                options: {
                    filter: false,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <Tooltip title={"Show"}>
                            <IconButton className={classes.iconButton + " " + classes.muiTableAction} onClick={() => this.handleShow(tableMeta.rowIndex)} >
                                <ArrowForward className={classes.icon} />
                            </IconButton>
                        </Tooltip>
                    }
                }
            },
        ]

        const ruleAction = [
            { label: "Show Trend", callback: this.showTrend, icon: ShowChart },
        ]

        return (
            <React.Fragment>
                <main className={classes.layout1}>
                    <div className={classes.root}>
                        <p className={classes.title} align="left">History</p>
                        <DataTable
                            title="Recent History"
                            key={filterList.length}
                            // filterType={'dropdown'}
                            columns={columns}
                            selectableRows={true}
                            filter={true}
                            textLabelsBody={fetchingRec ? "Fetching Records..." : "No Records Found"}
                            data={this.state.data}
                            onRowsDelete={this.handleDelete}
                            //trending option is not rendering to UI. Waiting for backend service to support below option
                            // toolBarAction={filterList.length <= 5 && filterList.length !== 0 ? ruleAction : []}
                            onFilterChange={this.onFilterChange}
                            customToolbarSelect={(selectedRows, displayData, setSelectedRows) => <CustomToolbarSelect actionList={[{ title: "Compare", eventCallback: this.handleClickCompare, icon: CompareArrows }, { title: "Delete", eventCallback: this.handleDelete, alertTitle: "This will remove the selected record(s)", icon: Delete }]} selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />}
                        />
                    </div>
                    <div ref={(ref) => (ref ? this.trendRef = ref : null)} >
                        {this.state.trendGraph ? <div className={classes.root}>
                            {<Highstock showHisDetailsfromGraph={this.showHisDetailsfromGraph} data={this.state.trendGraph} />}
                        </div> : null}
                    </div>
                    <div ref={(ref) => (ref ? this.comRef = ref : null)} className={classes.root}>
                        <Grid container>
                            <Grid item sm={4}>
                                <div style={{ paddingLeft: 35 }}>{this.state.loading ? (<div>Comparing<LinearProgress className={classes.scancolor} /></div>) : null}</div>
                            </Grid>
                        </Grid>
                        {this.state.compare && this.state.compare.length > 1 && comparedata && comparedata2 ?
                            <Grid container>
                                <Grid item style={{ paddingTop: 0 }} align="left">
                                    <div className={classes.name} >Comparison of {this.state.compare[0].url}'s report</div><br />
                                </Grid>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Compare Data 1</TableCell>
                                            <TableCell>Compare Data 2</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <React.Fragment>
                                            <TableRow key={1}>
                                                <TableCell component="th" scope="row">
                                                    <Chip label={"Scaned on"} icon={<Timeline />} className={classes.chip} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {compare[0].formatedDate ? compare[0].formatedDate : ''}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {compare[1].formatedDate ? compare[1].formatedDate : ''}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={2}>
                                                <TableCell component="th" scope="row">
                                                    <Chip label={"Scan completed in"} icon={<AccessTime />} className={classes.chip} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {comparedata && comparedata.exectime && comparedata.exectime + " milliseconds"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {comparedata2 && comparedata2.exectime && comparedata2.exectime + " milliseconds"}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={3}>
                                                <TableCell component="th" scope="row">
                                                    <Chip label={"Number of found violations"} icon={<ErrorOutline />} className={classes.chip} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <span style={this.compareDataStyle(compare[0].formatedDate, compare[1].formatedDate, comparedata.totalviolations, comparedata2.totalviolations, true)}>{comparedata.totalviolations}</span>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <span style={this.compareDataStyle(compare[1].formatedDate, compare[0].formatedDate, comparedata2.totalviolations, comparedata.totalviolations, true)}>{comparedata2.totalviolations}</span>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={4}>
                                                <TableCell component="th" scope="row">
                                                    <Chip label={"Number of pages scanned"} icon={<DoneAll />} className={classes.chip} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {comparedata.pagesCount}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {comparedata2.pagesCount}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={5}>
                                                <TableCell component="th" scope="row">
                                                    <Chip label={"Number of pages with violation"} icon={<HighlightOff />} className={classes.chip} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <span style={this.compareDataStyle(compare[0].formatedDate, compare[1].formatedDate, comparedata.pagesWithViolations, comparedata2.pagesWithViolations, true)}>{comparedata.pagesWithViolations}</span>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <span style={this.compareDataStyle(compare[1].formatedDate, compare[0].formatedDate, comparedata2.pagesWithViolations, comparedata.pagesWithViolations, true)}>{comparedata2.pagesWithViolations}</span>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={6}>
                                                <TableCell component="th" scope="row">
                                                    <Chip label={"Number of pages without violation"} icon={<DoneIcon />} className={classes.chip} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <span style={this.compareDataStyle(compare[0].formatedDate, compare[1].formatedDate, comparedata.pagesWithoutViolations, comparedata2.pagesWithoutViolations)}>{comparedata.pagesWithoutViolations}</span>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <span style={this.compareDataStyle(compare[1].formatedDate, compare[0].formatedDate, comparedata2.pagesWithoutViolations, comparedata.pagesWithoutViolations)}>{comparedata2.pagesWithoutViolations}</span>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow key={7}>
                                                <TableCell component="th" scope="row">
                                                    <Chip label={"Percentage of pages without violation"} icon={<VerifiedUser />} className={classes.chip} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <span style={this.compareDataStyle(compare[0].formatedDate, compare[1].formatedDate, comparedata.perctWithoutViolations, comparedata2.perctWithoutViolations)}>{(comparedata.perctWithoutViolations !== undefined ? comparedata.perctWithoutViolations + " %" : "")}</span>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <span style={this.compareDataStyle(compare[1].formatedDate, compare[0].formatedDate, comparedata2.perctWithoutViolations, comparedata.perctWithoutViolations)}>{(comparedata2.perctWithoutViolations !== undefined ? comparedata2.perctWithoutViolations + " %" : "")}</span>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>

                                        <TableRow key={8}>
                                            <TableCell component="th" scope="row" colSpan={3}>
                                                <Grid container spacing={24}>
                                                    <Grid item sm={6}>
                                                        {/* {this.charts(this.state.comparedata, (this.state.compare.length > 0 && this.state.compare[0].formatedDate ? this.state.compare[0].formatedDate : null))} */}
                                                        {console.log(this.state)}
                                                        <ViolationCharts data={{
                                                            score: Math.round(this.state.comparedata.score),
                                                            total: this.state.comparedata.totalviolations,
                                                            major: this.state.comparedata.major ? this.state.comparedata.major.length : 0,
                                                            minor: this.state.comparedata.minor ? this.state.comparedata.minor.length : 0,
                                                            critical: this.state.comparedata.critical ? this.state.comparedata.critical.length : 0
                                                        }} width={8}/>
                                                    </Grid>
                                                    <Grid item sm={6}>
                                                        {/* {this.charts(this.state.comparedata2, (this.state.compare.length > 1 && this.state.compare[1].formatedDate ? this.state.compare[1].formatedDate : null))} */}
                                                        <ViolationCharts data={{
                                                            score: Math.round(this.state.comparedata2.score),
                                                            total: this.state.comparedata2.totalviolations,
                                                            major: this.state.comparedata2.major ? this.state.comparedata2.major.length : 0,
                                                            minor: this.state.comparedata2.minor ? this.state.comparedata2.minor.length : 0,
                                                            critical: this.state.comparedata2.critical ? this.state.comparedata2.critical.length : 0
                                                        }} width={8} />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                        {/* {comparedata.options && comparedata2.options ?
                                            <TableRow key={9}>
                                                <TableCell component="th" scope="row" colSpan={3}>
                                                    <Grid container justify="center">
                                                        <Grid item >
                                                            <RadioGroup
                                                                row
                                                                name="type"
                                                                className={classes.group}
                                                                value={this.state.type}
                                                                onChange={this.handleType}
                                                            >
                                                                <FormControlLabel value="pie" control={<Radio />} label="Pie" />
                                                                <FormControlLabel value="column" control={<Radio />} label="Column" />
                                                            </RadioGroup>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>

                                            </TableRow>
                                            : null} */}
                                    </TableBody>
                                </Table>
                            </Grid>
                            : ''}
                    </div>
                </main>
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(AuthenticatedPage(WithDashboardScan(withSnackbar(History))));