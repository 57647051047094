import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import AuthenticatedPage from "../../AuthenticatedPage";
import { withStyles } from '@material-ui/core/styles';
import ANDI from "./ANDI.jsx";
import Userway from "./Userway.jsx";
import AccessbotPlugin from "./AccessbotPlugin.jsx";

const styles = theme => ({
    bdyMinHght: {
        minHeight: "400px",
        marginBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 8
    },
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    },
    name: {
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.name.lineHeight
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 9,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
})
class Plugins extends Component {
    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                <main className={classes.layout1}>
                    <ANDI />
                    <Userway />
                    <AccessbotPlugin />
                </main>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AuthenticatedPage(Plugins));