import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

const styles = {
  card: {
    border: '1px solid #ccc',
    height: '375px',
    borderRadius: '30px',
    margin: 20,
  },
  area: {
    marginTop: 10,
  },
  heading: {
    backgroundColor: '#027db4',
    color: 'white',
    borderRadius: '30px 30px 0px 0px',
    fontSize: '10px',
    height: '45px',
  },
  content: {
    textAlign: 'left',
  },
  colorPrimary: {
    backgroundColor: '#00695C',
  },
  major: {
    backgroundColor: 'brown',
  },
  minor: {
    backgroundColor: 'gray',
  },
  critical: {
    backgroundColor: 'red',
  },
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

class ViolationCharts extends React.Component {
  getScoreOptions = (score) => {
    let options = {
      chart: {
        type: 'pie',
        marginBottom: 100,
      },
      title: {
        text: null,
      },
      subtitle: {
        text: `${score}/100`,
        floating: true,
        style: {
          fontSize: 20,
          fontWeight: 'bold',
          color: '#000000',
        },
        y: 160,
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          data: [
            { name: 'Score', y: score },
            { name: 'Major', y: 100 - score },
          ],
        },
      ],
      plotOptions: {
        pie: {
          showInLegend: false,
          innerSize: '70%',
          colors: ['green', 'white'],
          dataLabels: {
            enabled: false,
            distance: -14,
            color: 'white',
            style: {
              fontweight: 'bold',
              fontsize: 50,
            },
          },
        },
      },
    };
    return options;
  };

  getViolationChartOptions = (data) => {
    let minor = data.minor;
    let major = data.major;
    let critical = data.critical;
    let total = data.total;
    let options = {
      chart: {
        type: 'pie',
        marginBottom: 100,
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        x: 40,
        y: 0,
      },
      title: {
        text: null,
      },
      subtitle: {
        text: `${total} Violations`,
        floating: true,
        style: {
          fontSize: 20,
          fontWeight: 'bold',
          color: '#000000',
        },
        y: 160,
      },
      tooltip: {
        enabled: true,
      },
      series: [
        {
          data: [
            { name: 'Minor', y: minor },
            { name: 'Major', y: major },
            { name: 'Critical', y: critical },
          ],
        },
      ],
      plotOptions: {
        pie: {
          showInLegend: true,
          innerSize: '70%',
          colors: ['yellow', 'brown', 'red'],
          dataLabels: {
            enabled: false,
            distance: -14,
            color: 'white',
            style: {
              fontweight: 'bold',
              fontsize: 50,
            },
          },
        },
      },
    };
    return options;
  };

  render() {
    const { classes } = this.props;
    let data = this.props.data;
    let width = this.props.width ? this.props.width : 4;
    // let data = {
    //   score:  100,
    //   total: 82,
    //   major: 75,
    //   minor: 5,
    //   critical: 2
    // }

    return (
      <React.Fragment>
        <Grid container className={classes.area}>
          <Grid item sm={width} style={{ paddingLeft: 10 }}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.heading}
                titleTypographyProps={{
                  variant: 'body2',
                  fontWeight: 'fontWeightBold',
                }}
                title="Compliance Score"
              />
              <CardContent>
                <div>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={this.getScoreOptions(data.score ? data.score : 0)}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={width} style={{ paddingLeft: 10 }}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.heading}
                titleTypographyProps={{ variant: 'body2' }}
                title="Violation Chart"
              />
              <CardContent>
                <div>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={this.getViolationChartOptions(data)}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={width} style={{ paddingLeft: 10 }}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.heading}
                titleTypographyProps={{ variant: 'body2' }}
                title="Violation Breakdown"
              />
              <CardContent className={classes.content}>
                <div className={classes.content}>
                  <Typography variant="body1">Minor</Typography>
                  <Typography variant="caption">
                    {data.minor} {data.minor === 1 ? 'Error' : 'Errors'}{' '}
                  </Typography>
                  <BorderLinearProgress
                    variant="determinate"
                    classes={{ barColorPrimary: classes.minor }}
                    value={(data.minor / data.total) * 100}
                  />
                  <br />
                  <Typography variant="body1">Major</Typography>
                  <Typography variant="caption">
                    {data.major} {data.major === 1 ? 'Error' : 'Errors'}
                  </Typography>
                  <BorderLinearProgress
                    variant="determinate"
                    classes={{ barColorPrimary: classes.major }}
                    value={(data.major / data.total) * 100}
                  />
                  <br />
                  <Typography variant="body1">Critical</Typography>
                  <Typography variant="caption">
                    {data.critical} {data.critical === 1 ? 'Error' : 'Errors'}
                  </Typography>
                  <BorderLinearProgress
                    variant="determinate"
                    classes={{ barColorPrimary: classes.critical }}
                    value={(data.critical / data.total) * 100}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
ViolationCharts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViolationCharts);
