import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { showSnackbar } from '../common/Utils';

const styles = theme => ({
    menuButton: {
        marginLeft: 170,
        marginRight: 0,
        height: 46
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginBottom: 100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight,
        margin: '1.46rem 0 1.168rem 0'
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: theme.button.width,
        padding: theme.button.padding,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
})

function getQueryParamValue(query, param) {
    if(query[0] === '?'){
      query = query.substr(1);
    }
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === param) {
            return decodeURIComponent(pair[1]);
        }
    }
  }


class Resetpassword extends React.Component {
    constructor() {
        super()
        this.state = {
            currentPassword: '',
            newPassword: '',
            repeatPassword: '',
            message: '',
            variant: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentDidMount() {
        ValidatorForm.addValidationRule('ispass', (value) => {
            if (value.length < 8) {
                return false;
            }
            return true;
        });
    }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleSubmit() {
        let data = {
            token :  getQueryParamValue(this.props.location.search, 'token') || '',
            //id: this.props.loggedInUserObj.userDetails.email,
            //access_token: this.props.loggedInUserObj.userDetails.accessToken,
            //currentPassword: this.state.currentPassword,
            password: this.state.newPassword,
        }
        axios.post('/api/user/new-password', data).then((res) => {
            if (res.data.status === 'failed'){
                showSnackbar({
                    message: res.data.message,
                    variant: 'error'
                }, this)
            } else if (res.data.status === 'success'){
                showSnackbar({
                    message: 'Password changed successfully!',
                    variant: 'success'
                }, this)
                this.props.history.push("/dashboard");
            } else {
                showSnackbar('something went wrong.', this)
            }   
        })
    }
    render() {
        const { classes } = this.props;

        return (
            <div>
                <main className={classes.layout}>
                    <Grid container>
                        <Grid item sm={2}></Grid>
                        <Grid item sm={9} style={{ paddingTop: 70 }} align="left">
                            <div className={classes.title}>Reset Password.</div><br />

                            <ValidatorForm onSubmit={this.handleSubmit} instantValidate={true}>
                                <br /><br />
                                <Grid container spacing={8} alignItems="flex-end">

                                    <Grid item sm={6}>
                                        <TextValidator
                                            label="New Password"
                                            tabIndex={101}
                                            name="newPassword"
                                            id="newPassword"
                                            validators={['required', 'ispass']}
                                            errorMessages={['This field is required', 'Password should contain minimum 8 character']}
                                            onChange={this.handleChange}
                                            value={this.state.newPassword}
                                            type="password"
                                            fullWidth
                                            required
                                            InputLabelProps={{
                                                className: classes.label,
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br /><br />
                                <Grid container spacing={8} alignItems="flex-end">

                                    <Grid item sm={6}>
                                        <TextValidator
                                            label="Repeat Password"
                                            name="repeatPassword"
                                            id="repeatPassword"
                                            tabIndex={102}
                                            onChange={this.handleChange}
                                            value={this.state.repeatPassword}
                                            type="password"
                                            validators={['required', `isStringEqual:${this.state.newPassword}`]}
                                            errorMessages={['This field is required', 'New Password, Repeat Password do not match']}
                                            fullWidth
                                            required
                                            InputLabelProps={{
                                                className: classes.label,
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br /><br />

                                <Grid container>
                                    <Grid item>
                                        <Button id="resetPass" type="submit" variant="contained" tabIndex={103} className={classes.button}>
                                            RESET
                                            <FontAwesomeIcon icon={faSyncAlt} className={classes.rightIcon} />
                                        </Button>
                                    </Grid>
                                </Grid>

                            </ValidatorForm>
                        </Grid>
                        <Grid item sm={1}></Grid>
                    </Grid>
                    <br />
                </main>
            </div>
        );
    }
}

export default withStyles(styles)(withSnackbar(Resetpassword));