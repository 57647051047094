import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink, withRouter } from 'react-router-dom';
//import { connect } from 'react-redux';
//import { logout } from '../../../../actions/userActions';
import { withStyles } from '@material-ui/core/styles';
import AuthenticatedPage from "../../../AuthenticatedPage";
import { Link } from "react-router-dom";
import logo from '../../../assets/WhiteLogo.png'
import Tooltip from '@material-ui/core/Tooltip';


const styles = theme => ({
	root: {
		boxShadow: 'none',
		//color: theme.navbar.color,
	},
	menuButton: {
		height: 46
	},
	flexGrow: {
		flexGrow: 1
	},
	signOutButton: {
		marginLeft: theme.spacing(1)
	},
	bgClr: {
		backgroundColor: theme.bgClr.backgroundColor,
	},

});

class Topbar extends Component {

	componentDidMount() {
		// if (!this.props.userId) {
		// 	this.props.history.push('/');
		// }
	}

	render() {
		const { classes, className, onSidebarOpen, logout, history, staticContext, userId, ...rest } = this.props;
		return (
			<AppBar
				{...rest}
				className={clsx(classes.root, className)}
			>
				<Toolbar className={clsx(classes.root, classes.bgClr)}>
					{/* <RouterLink to="/home/dashboard">
						<Typography variant="h4" gutterBottom style={{ color: "white" }}>
							Medha - ATAF
      					</Typography>
					</RouterLink> */}
					<Typography variant="h6" color="inherit" >
						<Link /*component={Link}*/ to="/home/dashboard"><img tabIndex={0} src={logo} className={classes.menuButton} alt="logo" /></Link>
					</Typography>
					<div className={classes.flexGrow} />
					<Hidden mdDown>
						<Tooltip title="No Notifications" aria-label="No Notifications">
							<IconButton color="inherit">
								<Badge
									badgeContent={0}
									color="primary"
									variant="dot"
								>
									<NotificationsIcon />
								</Badge>
							</IconButton>
						</Tooltip>

						<Tooltip title="Logout" aria-label="Logout">
							<IconButton
								onClick={() => {
									// logout();
									// history.push('/sign-in')
									localStorage.clear()
									window.location.href = "/login"
								}}
								className={classes.signOutButton}
								color="inherit"
							>
								<InputIcon />
							</IconButton>
						</Tooltip>
					</Hidden>
					<Hidden lgUp>
						<IconButton
							color="inherit"
							onClick={onSidebarOpen}
						>
							<MenuIcon />
						</IconButton>
					</Hidden>
				</Toolbar>
			</AppBar>
		);
	}
}

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func
};

// const mapStateToProps = state => ({
// 	userId: state.user._id
// });

//export default withRouter(connect(mapStateToProps, { /*logout*/ })(withStyles(styles)(Topbar)));


export default withStyles(styles)(AuthenticatedPage(Topbar));