import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import blue from '@material-ui/core/colors/blue';

const styles = theme => ({
    container: {
        maxWidth: "1170px",
    },
    bgClr: {
        backgroundColor: theme.bgClr.backgroundColor,
    },
    // root: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    // },
    // menuButton: {
    //     marginLeft: 170,
    //     marginRight: 0,
    //     height: 46
    // },
    // rightIcon: {
    //     marginLeft: theme.spacing.unit,
    // },
    // icon: {
    //     position: "absolute",
    //     left: "0",
    //     bottom: "18%",
    //     width: "1.5rem",
    //     fontSize: "2rem",
    //     color: "#fff"
    // },
    // textField: {
    //     marginLeft: "18%",
    // },
    // pos_rel:
    // {
    //     position: "relative"
    // },
    // socialIcn: {
    //     display: "flex",
    //     justifyContent: 'center',
    //     alignItems: "flex-end",
    //     color: "#fff",
    //     cursor: 'pointer'
    // },
    // textLeft: {
    //     textAlign: "center"
    // },
    secPad: {
        padding: "0"
    },
    // textHeading: {
    //     color: '#fff',
    //     lineHeight: '110%',
    //     fontSize: '1.64rem',
    //     margin: '0.82rem 0 0.656rem 0',
    //     fontWeight: 400
    // },
    footer: {
        color: '#fff',
        fontSize: theme.typography.pxToRem(19),
        bottom: 0,
        top: 'inherit !important' ,
        position: 'fixed',
        width: '100%',
        zIndex: '120',
        height: 50,
        padding: 10,
    },
});
class Footer extends Component {
    state = {}
    render() {
        const { classes } = this.props
        return (

            <React.Fragment>
            <div id="footer">
                {/* <div className={classes.bgClr+" "+classes.secPad}>
                    <Grid container className={classes.container}>
                        <Grid item md={4}>
                        <ValidatorForm onSubmit={this.handleSubmit} className={classes.container}>
                            <Grid container spacing={8} alignItems="flex-end">
                                <Grid item md={6} className={classes.pos_rel}>
                                    <FontAwesomeIcon icon={faUserAlt} className={classes.icon} />
                                    <TextValidator
                                        label="First Name"
                                        name="fname"
                                        onChange={this.handleChange}
                                        value={this.state.fname}
                                        fullWidth
                                        color='#fff'
                                        className={classes.textField}
                                    />
                                </Grid>
                                <Grid item md={6} className={classes.pos_rel}>
                                    <FontAwesomeIcon icon={faEnvelope} className={classes.icon}  />
                                    <TextValidator
                                        label="Email"
                                        id="email"
                                        onChange={this.handleChange}
                                        name="email"
                                        value={this.state.email}
                                        withRequiredValidator
                                        validators={['required', 'isEmail']}
                                        errorMessages={['this field is required', 'email is not valid']}
                                        fullWidth
                                        className={classes.textField}
                                    />
                                </Grid>
                                <Grid item md={6} className={classes.pos_rel}>
                                    <FontAwesomeIcon icon={faEdit} className={classes.icon}  />
                                    <TextValidator
                                        id="standard-multiline-flexible"
                                        label="Multiline"
                                        multiline
                                        rows="2"
                                        fullWidth
                                        margin="normal"
                                        className={classes.textField}
                                    />
                                </Grid>
                            </Grid>
                    </ValidatorForm>
                    </Grid>
                    <Grid item sm = {3}></Grid>
                    <Grid item sm = {3} className={classes.textLeft} >
                        <h5 className={classes.textHeading}>accessbot.io</h5>
                        
                        <Link component={RouterLink} to="/index" style={{color:'#fff'}}>Home</Link><br/>
                        <Link style={{color:'#fff', cursor:'pointer'}}  onClick={() => {window.open('https://blog.accessbot.io/','_blank')}}>Blog</Link>
                    </Grid>
                    <Grid item sm = {3} className={classes.textLeft}>
                        <h5 className={classes.textHeading}>Social</h5>
                        
                        <Link className={classes.socialIcn} onClick={() => {window.open('https://www.facebook.com/access.bot','_blank')}}><img src="https://img.icons8.com/material/36/ffffff/facebook.png" alt="facebook logo" /><span style={{paddingBottom:5}}>Facebook</span></Link>
                        <Link className={classes.socialIcn} onClick={() => {window.open('https://www.linkedin.com/accessbot','_blank')}}><img src="https://img.icons8.com/material/36/ffffff/linkedin.png" alt="linkedin logo" /><span style={{paddingBottom:5}}>Linkedin</span></Link>
                        <Link className={classes.socialIcn} onClick={() => {window.open('https://github.com/accessbot','_blank')}}><img src="https://img.icons8.com/ios-glyphs/36/ffffff/github.png" alt="github logo" /><span style={{paddingBottom:5}}>GitHub</span></Link>
                    </Grid>
                    <Grid item sm = {3}></Grid>
                </Grid>
                <br />
            </div> */}
                <div className={classes.bgClr + " " + classes.secPad + " " + classes.footer} >
                    <Grid><span >Copyright @AccessBot. All rights reserved.</span></Grid>
                </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Footer);