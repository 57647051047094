import React from 'react';
import queryString from 'query-string';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { showSnackbar } from '../common/Utils';

class AccountConfirmation extends React.Component {

    componentDidMount() {
        const { ref } = queryString.parse(this.props.location.search);
        let data = {
            ref: ref.replace("account_confirmation_", "")
        }
        axios.post('/api/user/accountconfirmation', data).then((res) => {
            if (res.data.snackbar)
                showSnackbar(res.data.snackbar, this)
            this.props.history.push('/login');
        })
    }

    render() {
        return null;
    }

}

export default withSnackbar(AccountConfirmation);