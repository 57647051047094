import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import logo from '../assets/WhiteLogo.png'
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom";
import axios from 'axios'
import {
    faHome,
    faTasks,
    faClock,
    faHistory,
    faCog,
    faLink,
    faUser,
    faKey,
    faSignOutAlt,
    faUniversalAccess
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { allowUser } from '../common/Utils';
import AuthenticatedPage from "../AuthenticatedPage";

function Logout() {
    axios.post('/api/user/logout').then(response => {
        //console.log(response);
    })
    localStorage.clear()
    window.location.href = "/login"
}
const styles = theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        height: 46
    },
    root: {
        flexGrow: 1,
    },
    bgClr: {
        backgroundColor: theme.bgClr.backgroundColor,
    },
});

class SwipeableTemporaryDrawer extends React.Component {
    state = {
        left: false,

    };

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };
    handleApi() {
        axios.get('/api/user/getDomine').then((res) =>
            window.open(`${res.data.domine}/jsondoc-ui.html?url=${res.data.domine}/jsondoc`, "_blank")
        );
    }
    logout() {
        axios.post('/api/user/logout').then(() => { })
        localStorage.clear()
        window.location.href = "/login"
    }
    render() {
        const { classes, currentUser } = this.props;
        const menuList = [] //[{ 'Manage Rules': '/home/rules' }, { 'Schedule Scans': '/home/schedule' }, { 'History': '/home/history' }, { 'Plugins': '/home/plugins' }, { 'Settings': '/home/settings' }]
        if (allowUser(currentUser, "MANAGE_RULES")) {
            menuList.push({ 'Manage Rules': '/home/rules' })
        }
        if (allowUser(currentUser, "SCHEDULE_SCANS")) {
            menuList.push({ 'Schedule Scans': '/home/schedule' })
        }
        menuList.push({ 'History': '/home/history' })
        if (allowUser(currentUser, "PLUGINS")) {
            menuList.push({ 'Plugins': '/home/plugins' })
        }
        menuList.push({ 'Settings': '/home/settings' })
        menuList.push({ 'ImageLabels': '/home/image-labels' })
        menuList.push({ 'ImageText': '/home/image-text' })

        const sideList = (
            <div className={classes.list}>
                <List>
                    {[{ 'Dashboard': '/home/dashboard' }].map((text, index) => (
                        <ListItem button value='dashboard' key={Object.keys(text)} component={Link} to={text[Object.keys(text)]}>
                            <ListItemIcon><FontAwesomeIcon icon={faHome} /></ListItemIcon>
                            <ListItemText primary={Object.keys(text)} />
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    {menuList.map((text, index) => (
                        <ListItem button value='options' key={Object.keys(text)} component={Link} to={text[Object.keys(text)]}>
                            <ListItemIcon>{index === 0 ? <FontAwesomeIcon icon={faTasks} /> : index === 1 ? <FontAwesomeIcon icon={faClock} /> : index === 2 ? <FontAwesomeIcon icon={faHistory} /> : index === 3 ? <FontAwesomeIcon icon={faUniversalAccess} /> : <FontAwesomeIcon icon={faCog} />}</ListItemIcon>
                            <ListItemText primary={Object.keys(text)} />
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    <ListItem button value='api' onClick={this.handleApi}>
                        <>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faLink} />
                            </ListItemIcon>
                            <ListItemText primary="API" />
                        </>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    {[{ 'My Account': '/home/profile' }, { 'Reset Password': '/home/resetpassword' }].map((text, index) => (
                        <ListItem button value='account' key={Object.keys(text)} component={Link} to={text[Object.keys(text)]}>
                            <ListItemIcon>{index === 0 ? <FontAwesomeIcon icon={faUser} /> : <FontAwesomeIcon icon={faKey} />}</ListItemIcon>
                            <ListItemText primary={Object.keys(text)} />
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    <ListItem button value='logout' onClick={Logout} >
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </div>
        );


        return (
            <div className={classes.root}>
                <AppBar position="fixed" className={classes.bgClr}>
                    <Toolbar>
                        <IconButton className={classes.menuButton} onClick={this.toggleDrawer('left', true)} color="inherit" aria-label="Menu">
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            <img src={logo} className={classes.menuButton} alt="logo" />
                        </Typography>
                    </Toolbar>
                </AppBar>
                <SwipeableDrawer
                    open={this.state.left}
                    onClose={this.toggleDrawer('left', false)}
                    onOpen={this.toggleDrawer('left', true)}
                >
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer('left', false)}
                        onKeyDown={this.toggleDrawer('left', false)}
                    >
                        {sideList}
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}

SwipeableTemporaryDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AuthenticatedPage(SwipeableTemporaryDrawer));
