import React, { Component } from 'react';
import axios from 'axios';
import Grid from "@material-ui/core/Grid";
import AuthenticatedPage from "../AuthenticatedPage";
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../common/DataTable';
import AddCircle from "@material-ui/icons/AddCircle";
import DialogBox from './ScheduleDialogBox';
import Delete from "@material-ui/icons/Delete";
import CustomToolbarSelect from '../common/CustomToolbarSelect';
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../common/Utils';

const styles = theme => ({
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 9,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    }
})

class ScheduleScan extends Component {
    constructor() {
        super()
        this.state = {
            scheduledata: [],
            deleterow: false,
            fetchingRec: true,
            open: false,
        }
        this.handleDelete = this.handleDelete.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
    }
    message = () => {
        alert('Failed! Reason User must login or provide a valid access_token ')
    }
    async componentDidMount() {
        const { userDetails } = this.props.currentUser
        if (this.props.loggedInUserObj.userDetails.accessToken === "guest") {
            setTimeout(this.message, 1000)
        } else {
            let data = {
                access_token: userDetails.accessToken,
                role: userDetails.role,
            }
            axios.post('/api/user/schedulescan', data).then((result) => {
                this.setState({
                    fetchingRec: false,
                    scheduledata: result.data.schedulescan
                })
            }).catch(() => {
                this.setState({ fetchingRec: false })
                this.props.enqueueSnackbar('Something went wrong', { variant: "error", autoHideDuration: 3000 })
            }
            );
        }
    }
    handleDelete = (delArray) => {
        const { userDetails } = this.props.currentUser
        var scheduledata = this.state.scheduledata;
        var delIds = [];
        delArray.forEach(del => {
            let id = scheduledata[del['dataIndex']][3];
            delIds.push(id);
            let data = {
                access_token: userDetails.accessToken,
                role: userDetails.role,
                id: id
            }
            axios.post('/api/user/deleteschedule', data).then((res) => { showSnackbar(res.data.snackbar, this) }).catch((err) => showSnackbarError(err.message, this))
        });
        scheduledata = scheduledata.filter(fil => !delIds.includes(fil[3]));
        this.setState({ scheduledata: scheduledata });
    }
    handleUpdate = () => {
        const { userDetails } = this.props.currentUser
        let data = {
            access_token: userDetails.accessToken,
            role: userDetails.role,
        }
        axios.post('/api/user/schedulescan', data).then((res) => {
            this.setState({ scheduledata: res.data.schedulescan })
        }).catch((err) => showSnackbarError(err.message, this))
    }
    handleClickOpen = (data) => {
        this.setState({
            open: true,
            ruledata: data
        });
    };
    handleClickOpenModal = () => {
        this.setState({
            open: false,
        });
    }
    handleClose = () => {
        this.setState({
            deleterow: false
        });
    }

    render() {
        const { classes } = this.props;
        const { fetchingRec } = this.state;


        const columns = [
            {
                name: "Website",
                options: {
                    filter: false,
                }
            },
            {
                name: "Hours",
                options: {
                    filter: false,
                }
            },
            {
                name: "Email",
                options: {
                    filter: false,
                }
            },
        ];

        const toolBarAction = [{ label: "ADD RECORD", callback: this.handleClickOpen, icon: AddCircle }]

        return (
            <React.Fragment>

                {/* <AlertDialog
                    open={this.state.deleterow}
                    handleClose={this.handleClose}
                    title={'Do you want to hide this Violation?'}
                    footer={[{label: "Cancel", callback: this.handleClose}, 
                    {label: "OK", callback: this.handleDelete}]}
                    >
                </AlertDialog> */}

                {this.state.open && <DialogBox handleUpdate={this.handleUpdate} handleClickModal={this.handleClickOpenModal} open={this.state.open} />}


                <div className={classes.layout1}>
                    <Grid container>
                        <Grid item sm={12} >
                            <p className={classes.title} align="left">Schedule Website</p>
                            <DataTable
                                title="Schedule Website"
                                columns={columns}
                                selectableRows={true}
                                data={this.state.scheduledata}
                                textLabelsBody={fetchingRec ? "Fetching Records..." : "No Records Found"}
                                toolBarAction={toolBarAction}
                                customToolbarSelect={(selectedRows, displayData, setSelectedRows) => <CustomToolbarSelect actionList={[{ title: "Delete", eventCallback: this.handleDelete, alertTitle: "Do you want to delete selected schedule(s) ?", icon: Delete }]} selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />}
                            />
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>);
    }
}

export default withStyles(styles)(AuthenticatedPage(withSnackbar(ScheduleScan)));