import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import AuthenticatedPage from "../../AuthenticatedPage";
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { showSnackbar, showSnackbarError } from '../../common/Utils';
import AlertDialog from '../../common/AlertDialog';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    bdyMinHght: {
        minHeight: "400px",
        marginBottom: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 8
    },
    title: {
        paddingTop: theme.title.paddingTop,
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(47),
        lineHeight: theme.title.lineHeight
    },
    name: {
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.name.lineHeight
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    script: {
        width: 200,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 430
        },
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 9,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: 120,
        padding: theme.button.padding,
        marginLeft: theme.spacing.unit,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
})
class Userway extends Component {

    state = {
        email: '',
        url: '',
        open: false,
    }

    handleChange = event => { this.setState({ [event.target.name]: event.target.value }) }

    handleCancel = () => { this.setState({ email: "", url: "" }) }

    handleClose = () => this.setState({ open: false })

    handleCopy = () => this.copyButton.click();

    handleSubmit = () => {
        const { userDetails } = this.props.currentUser
        const { email, url } = this.state;
        let data = {
            postData: {
                email: email,
                url: url,
            },
            access_token: userDetails.accessToken,
            role: userDetails.role,
        }
        axios.post('/api/user/getUserwayScript', data).then((res) => {
            if (res.data && res.data.script) {
                this.setState({ open: true, script: res.data.script })
            } else {
                showSnackbar(res.data.snackbar, this)
            }
        }).catch((err) => showSnackbarError(err.message, this))
    }

    render() {
        const { classes } = this.props
        const dialogContent = <>
            <TextField className={classes.script} multiline rowsMax="4" defaultValue={this.state.script} />
            <CopyToClipboard text={this.state.script}
                onCopy={() => this.setState({ copied: true })}>
                <span ref={ref => this.copyButton = ref} />
            </CopyToClipboard>
        </>
        let footer = [{ label: "Copy Script", callback: this.handleCopy }, { label: "Cancel", callback: this.handleClose }];
        return (
            <Grid item style={{ padding: "50px 20px 0px 20px" }} align="left">
                <AlertDialog
                    open={this.state.open}
                    handleClose={this.handleClose}
                    content={dialogContent}
                    title={"Accessibility Script"}
                    footer={footer}
                />
                <div className={classes.title} >Accessibility Widget</div><br />
                <p>Create an instance of the Accessibility Widget for a website.</p>
                <ol>
                    <li>Enter valid email and full site url then click on generate script.</li>
                    <li>Accessibility Script tag will be generated for the provided url.</li>
                    <li>Copy generated script tag and add to the HTML head.</li>
                </ol>
                <br />
                <ValidatorForm onSubmit={this.handleSubmit} instantValidate={true}>
                    <Grid container spacing={8} alignItems="flex-end">

                        <Grid item sm={6}>
                            <TextValidator
                                label="Email"
                                name="email"
                                id="email"
                                tabIndex={100}
                                onChange={this.handleChange}
                                value={this.state.email}
                                fullWidth
                                validators={['required', 'isEmail']}
                                required
                                errorMessages={['this field is required', 'Email is not valid']}
                                InputLabelProps={{
                                    className: classes.label,
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <br /><br />
                    <Grid container spacing={8} alignItems="flex-end">

                        <Grid item sm={6}>
                            <TextValidator
                                label="Site URL"
                                tabIndex={101}
                                name="url"
                                id="url"
                                onChange={this.handleChange}
                                value={this.state.url}
                                fullWidth
                                required
                                InputLabelProps={{
                                    className: classes.label,
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item sm={4} style={{ paddingTop: 25 }}>
                        <Button type='submit' style={{ width: 180, marginBottom: 5 }} value='submit' tabIndex={101} className={classes.button}>
                            Generate Script
                        <SendIcon className={classes.rightIcon} />
                        </Button>
                        <Button onClick={this.handleCancel} style={{ marginBottom: 5 }} value='submit' tabIndex={101} className={classes.button}>
                            Cancel
                        <Close className={classes.rightIcon} />
                        </Button>
                    </Grid>

                </ValidatorForm>
            </Grid>
        );
    }
}

export default withStyles(styles)(AuthenticatedPage(withSnackbar(Userway)));