import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';

class AlertDialog extends React.Component {

    render() {
        const { open = false, handleClose = () => null, title, footer = [], content = null } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={() => handleClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                    {content ?
                        <DialogContent>
                            {content}
                        </DialogContent>
                    : null}
                    <DialogActions>
                        {footer.map((button) =>
                            <Button value='alert' key={button.label} onClick={() => button.callback()} color="primary" >
                                {button.label}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AlertDialog;