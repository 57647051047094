import React from "react";
import { UserDashboardContextConsumer } from "../DashboardContext"

const WithDashboardScan = (WrappedComponent) => {
    return class UserDashboardWrapped extends React.Component {
        render() {
            return (
                <UserDashboardContextConsumer >
                    {dashboardObj => (
                        <WrappedComponent currentUrl={dashboardObj} {...this.props} />
                    )}
                </UserDashboardContextConsumer>)

        }
    }
}

export default WithDashboardScan;