import React, { Component } from 'react';
import axios from 'axios';
import Grid from "@material-ui/core/Grid";
import Highcharts from './Highcharts';
import AuthenticatedPage from "../AuthenticatedPage";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing.unit * 1,
        paddingBottom: theme.spacing.unit * 1,
        justifyContent: 'center',
    },

    title: {
        color: theme.title.color,
        fontSize: theme.typography.pxToRem(37),
        lineHeight: theme.title.lineHeight,
        textAlign: 'center'
    },
    chip: {
        margin: theme.spacing.unit,
    },
    table: {
        margin: '10 10 10 10',
        border: ' 1px solid black',
        borderCollapse: 'collapse',
        maxWidth: window.innerWidth < 500 ? 240 : 190,
        minWidth: '100%',
    },
    td: {
        padding: 5,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        maxWidth: window.innerWidth < 500 ? 240 : 190,
        minWidth: '90%',
    },
    tr: {
        pageBreakBefore: 'avoid',
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 9,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 980,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
});

class DashboardReport extends Component {
    state = {
        url: "",
        type: "",
        critical: [],
        major: [],
        minor: [],
        options: {},
        options2: {},
    }


    async componentDidMount() {
        document.getElementById("footer").style.display = 'none'
        document.getElementById("header").style.display = 'none'
        this.timer = 0;
        let id = localStorage.getItem("id")
        let url = localStorage.getItem("url")
        let type = localStorage.getItem("type")
        let data = {
            access_token: localStorage.getItem("accessToken"),
            url: `history:${id}`,
            user_agent: navigator.userAgent,
        }
        let res = await axios.post('/api/user/scansite', data)
        if (res.data.violations !== 0 && res.data.violations !== undefined) {
            this.setState({
                url: url,
                type: type,
                options: {
                    series: [{
                        data: res.data.violate,
                        name: '',
                        showInLegend: false
                    }],
                    xAxis: [{ categories: res.data.violations[0] }],
                    chart: { type: 'pie' },
                    title: { text: 'VIOLATIONS BY RULE' },
                    categories: res.data.violations[0],
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                },
                options2: {
                    series: [{
                        data: [{
                            name: 'Critical',
                            y: res.data.critical.length,
                            color: "red",
                        }, {
                            name: 'Major',
                            y: res.data.major.length,
                            color: "brown",
                        }, {
                            name: 'Minor',
                            y: res.data.minor.length,
                            color: "yellow",
                        }],
                        maxPointWidth: 50,
                        name: '',
                        showInLegend: false
                    }],
                    xAxis: { categories: res.data.severity[0], crosshair: true },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    chart: { type: 'pie' },
                    title: { text: 'VIOLATIONS BY SEVERITY' },
                },
                critical: res.data.critical,
                major: res.data.major,
                minor: res.data.minor,
                exectime: res.data.time,
                pagesCount: res.data.pagesCount,
                totalviolations: res.data.totalviolations,
                pagesWithViolations: res.data.pagesWithViolations,
                pagesWithoutViolations: res.data.pagesWithoutViolations,
                perctWithoutViolations: res.data.perctWithoutViolations,
                reportHeading: this.state.reportHeading,
                scannedOn: this.state.scannedOn,
            })
        } else if (res.data.snackbar) {
            this.setState({ loading: false })
        } else {
            this.setState({ message: "Everything Looks Good! No Violations Found" })
        }

    }

    render() {
        const { classes } = this.props;
        const data = this.state;
        console.log(data.options,data.options2)
        let tableData = [...data.critical, ...data.major, ...data.minor]
        const filterList = localStorage.getItem("filterList").split(":")
        let levelFound = false;
        if (filterList && filterList !== null) {
            if (filterList.length > 1 && filterList[1].length > 0)
                tableData = tableData.filter(eachrow => filterList[1].includes(eachrow[1]))
            if (filterList.length > 2 && filterList[2].length > 0)
                tableData = tableData.filter(eachrow => filterList[2].includes(eachrow[2]))
            if (filterList.length > 9 && filterList[9].length > 0)
                tableData = tableData.filter(eachrow => filterList[9].includes(eachrow[9]))
            if (filterList.length > 11 && filterList[11].length > 0) {
                tableData = tableData.filter(eachrow => {
                    levelFound = false
                    filterList[11].split(",").forEach(fil => {
                        if (fil === eachrow[11]) levelFound = true
                    })
                    return levelFound
                })
            }
        }
        return (
            <React.Fragment>
                {data.options2.series && data.options.series ?
                    <main className={classes.layout}>
                        <Grid item style={{ marginBottom: 10 }} align="left">
                            <div className={classes.title}>Scanned Report Of&nbsp; {data.url} </div>
                        </Grid>
                        <Grid item sm={1}></Grid>
                        <Grid item>
                            <table border='1' className={classes.table}  >
                                <thead>
                                    <tr >
                                        <th>Heading</th>
                                        <th>Value</th>
                                        <th>Heading</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr className={classes.tr}>
                                    <td  >
                                        Scan completed in
                                        </td>
                                    <td  >
                                        {data.exectime} milliseconds
                                        </td>
                                    <td  >
                                        Found
                                        </td>
                                    <td  >
                                        {data.totalviolations} violations
                                        </td>
                                </tr>
                                <tr className={classes.tr}>
                                    <td  >
                                        Number of pages scanned
                                        </td>
                                    <td  >
                                        {data.pagesCount}
                                    </td>
                                    <td  >
                                        Number of pages with violation
                                        </td>
                                    <td  >
                                        {data.pagesWithViolations}
                                    </td>
                                </tr>
                                <tr className={classes.tr}>
                                    <td  >
                                        Number of pages without violation
                                        </td>
                                    <td  >
                                        {data.pagesWithoutViolations}
                                    </td>
                                    <td  >
                                        Percentage of pages without violation
                                        </td>
                                    <td  >
                                        {data.perctWithoutViolations} %
                                        </td>
                                </tr>
                                </tbody>
                            </table>
                        </Grid>
                        <br />
                        <div id="printchart">
                            <Grid container spacing={24}>
                                <Grid item sm={6} style={{ cursor: "pointer" }}>
                                    <Highcharts options={{
                                        ...data.options,
                                        series: [{ ...data.options.series[0], showInLegend: false }],
                                        title: { text: 'VIOLATIONS BY RULE' },
                                        chart: { type: this.state.type ? this.state.type : 'pie' },
                                        credits: {
                                            enabled: false
                                        },
                                    }} />
                                </Grid>
                                <Grid item sm={6} style={{ cursor: "pointer" }}>
                                    <Highcharts options={{
                                        ...data.options2,
                                        title: { text: 'VIOLATIONS BY SEVERITY' },
                                        series: [{ ...data.options2.series[0], showInLegend: false }],
                                        chart: { type: this.state.type ? this.state.type : 'pie' },
                                        credits: {
                                            enabled: false
                                        },
                                    }} />
                                </Grid>
                            </Grid>
                        </div>
                        <br />
                        <section ref={(tableRef) => this.tableRef = tableRef}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <table border='1' className={classes.table}>
                                        <thead>
                                            <tr className={classes.tr}>
                                                <th>Url</th>
                                                <th>Severity</th>
                                                <th>Rule Name</th>
                                                <th>Issue Description</th>
                                                <th>Remediation Suggestion</th>
                                                <th>Source</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            tableData.map(eachRow =>
                                                <tr key={eachRow.toString()} className={classes.tr}>
                                                    <td className={classes.td}>{eachRow[0]}</td>
                                                    <td className={classes.td}>{eachRow[1]}</td>
                                                    <td className={classes.td}>{eachRow[2]}</td>
                                                    <td className={classes.td}>{eachRow[12]}</td>
                                                    <td className={classes.td}>{eachRow[4]}</td>
                                                    <td className={classes.td}>{eachRow[5]}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </section>
                    </main>
                    :
                    null
                }
            </React.Fragment>
        );

    }
}

export default withStyles(styles)(AuthenticatedPage(DashboardReport))
