import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SendIcon from '@material-ui/icons/Send';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import logo from '../assets/WhiteLogo.png'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator-vivek';
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import WithUser from "./WithUser";

const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing.unit * 1,
        paddingBottom: theme.spacing.unit * 1,
        justifyContent: 'center',
    },
    paper: {
        padding: theme.spacing.unit * 1,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    scancolor: {
        color: '#26a69',
    },
    url: {
        width: 80, wordWrap: 'break-word'
    },
    menuButton: {
        marginLeft: 170,
        marginRight: 0,
        height: 46
    },
});
class Home extends Component {
    constructor() {
        super()
        this.state = {
            url: '',
            renderto: '',
            loginSucess: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleSubmit = () => {
        this.setState({ renderto: '/home', loginSucess: true })
        this.props.currentUser.changeUser({
            firstName: 'Guest',
            isAuthenticated: true,
            accessToken: 'guest',
            plan: 'Guest',
            url: this.state.url
        });
    }
    handleChange = event => {
        this.setState({ url: event.target.value })
    }
    render() {
        const { classes } = this.props;
        const loginCompleted = this.props.currentUser.userDetails.isAuthenticated && this.state.loginSucess;
        return (
            <React.Fragment>
                {loginCompleted && <Redirect to={this.state.renderto} />}
                <AppBar position="static" style={{ backgroundColor: '#2196F3' }}>
                    <Toolbar>
                        <Grid container>
                            <Typography variant="h6" color="inherit" >
                                <img src={logo} className={classes.menuButton} alt="logo" />
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item sm={6}></Grid>
                            <Grid item>
                                <Link to="/login" style={{ textDecoration: 'none', color: 'white', fontSize: '19pt' }}>Login</Link>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <ValidatorForm className={classes.root} onSubmit={this.handleSubmit} >
                    <Grid container >
                        <Grid item sm={2}></Grid>
                        <Grid item sm={3}>
                            <TextValidator
                                label="Website Address"
                                id="url"
                                name="url"
                                value={this.state.url}
                                onChange={this.handleChange}
                                margin="normal"
                                style={{ width: 290 }}
                                InputLabelProps={{
                                    style: { fontSize: '19pt', position: 'relative', textAlign: 'left' },
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item sm={2} style={{ paddingTop: 50 }}>
                            <Button type='submit' value='submit' variant="contained" style={{ backgroundColor: "#26a69a", color: "white", width: 240 }} className={classes.button}>
                                Scan for errors
                            <SendIcon className={classes.rightIcon} />
                            </Button>
                        </Grid>

                    </Grid>
                </ValidatorForm>

            </React.Fragment>
        );
    }
}
Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WithUser(Home));
