import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import WithUser from './WithUser';
import {
  ValidatorForm,
  TextValidator,
} from 'react-material-ui-form-validator-vivek';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import logo from '../assets/WhiteLogo.png';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SendIcon from '@material-ui/icons/Send';
import Footer from './Footer';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withSnackbar } from 'notistack';
import { showSnackbarError } from '../common/Utils';

const styles = (theme) => ({
  menuButton: {
    marginLeft: theme.spacing.unit * 22,
    height: 46,
    display: 'flex',
    [theme.breakpoints.down(600 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 0,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  bdyMinHght: {
    minHeight: '400px',
    marginBottom: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 8,
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#2196F3',
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    minHeight: '400px',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginBottom: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  navlink: {
    textDecoration: 'none',
    color: theme.navbar.color,
    fontSize: theme.typography.pxToRem(20),
    textTransform: theme.navbar.textTransform,
    marginRight: theme.spacing.unit * 22,
    [theme.breakpoints.down(600 + theme.spacing.unit * 3 * 2)]: {
      marginRight: 0,
    },
  },
  title: {
    paddingLeft: theme.title.paddingLeft,
    color: theme.title.color,
    fontSize: theme.typography.pxToRem(47),
    lineHeight: theme.title.lineHeight,
  },
  label: {
    fontSize: theme.typography.pxToRem(25),
    position: theme.label.position,
    textAlign: theme.label.textAlign,
  },
  button: {
    backgroundColor: theme.button.backgroundColor,
    color: theme.button.color,
    width: theme.button.width,
    fontWeight: theme.button.fontWeight,
    padding: theme.button.padding,
    '&:hover': {
      background: theme.button.backgroundColor,
    },
    marginTop: '10px',
  },
  inputLabel: {
    marginTop: -6,
  },
  link: {
    marginLeft: 5,
  },
});

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      renderto: '',
      loginSucess: false,
      language: '',
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = () => {
    axios
      .post('/api/user/login', {
        email: this.state.email,
        password: this.state.password,
      })
      .then((res) => {
        if (res.data.status === 1 && res.data.data.accessToken !== undefined) {
          this.setState({ renderto: '/home', loginSucess: true });
          this.props.currentUser.changeUser({
            firstName: res.data.data.firstName,
            isAuthenticated: true,
            accessToken: res.data.data.accessToken,
            plan: res.data.data.plan,
            createdOn: res.data.data.createdOn,
            email: res.data.data.email,
            userId: res.data.data.id,
            role: res.data.data.role,
            roles: res.data.data.roles,
          });
        } else if (res.data.data === 'User is inactive.') {
          showSnackbarError('Account is inactive', this);
        } else {
          this.SnackMsg();
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };
  SnackMsg = () => {
    showSnackbarError('Invalid credential', this);
  };
  render() {
    const { classes } = this.props;
    const loginCompleted =
      this.props.currentUser.userDetails.isAuthenticated &&
      this.state.loginSucess;
    return (
      <div>
        {loginCompleted ? (
          <Redirect to={this.state.renderto} />
        ) : (
          <React.Fragment>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  className={classes.toolbarTitle}
                >
                  <img src={logo} className={classes.menuButton} alt="logo" />
                </Typography>

                {/* <Link to="/index" tabIndex={0} className={classes.navlink} >Home</Link> */}
              </Toolbar>
            </AppBar>
            <main className={classes.layout}>
              <Grid container>
                <Grid item sm={12} style={{ paddingTop: 70 }} align="left">
                  <div className={classes.title}>Login</div>
                  <br />

                  <ValidatorForm
                    onSubmit={this.handleSubmit}
                    onError={this.SnackMsg}
                    instantValidate={true}
                  >
                    <Grid container spacing={8} alignItems="flex-end">
                      <Grid item>
                        <AccountCircle style={{ fontSize: 30 }} />
                      </Grid>
                      <Grid item sm={5}>
                        <TextValidator
                          label="Email"
                          tabIndex={100}
                          id="email"
                          onChange={this.handleChange}
                          name="email"
                          value={this.state.email}
                          withRequiredValidator
                          validators={['required', 'isEmail']}
                          errorMessages={[
                            'this field is required',
                            'email is not valid',
                          ]}
                          fullWidth
                          required
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Grid container spacing={8} alignItems="flex-end">
                      <Grid item>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z" />
                        </svg>
                      </Grid>
                      <Grid item sm={5}>
                        <TextValidator
                          label="Password"
                          tabIndex={101}
                          id="password"
                          fullWidth
                          required
                          onChange={this.handleChange}
                          name="password"
                          type="password"
                          withRequiredValidator
                          value={this.state.password}
                          validators={['required']}
                          errorMessages={['this field is required']}
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Grid container>
                      <Grid item sm={1}></Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          value="submit"
                          variant="contained"
                          tabIndex={102}
                          className={classes.button}
                        >
                          LOGIN
                          <SendIcon className={classes.rightIcon} />
                        </Button>
                        {'  '}
                        <Button
                          component={Link}
                          to="/forgotpassword"
                          value="forgot password"
                          variant="contained"
                          tabIndex={103}
                          style={{ width: 200 }}
                          className={classes.button}
                        >
                          FORGOT PASSWORD
                          <SendIcon className={classes.rightIcon} />
                        </Button>
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                      <Grid item sm={1}></Grid>
                      <Grid item style={{ paddingLeft: 35 }}>
                        <p>
                          {' '}
                          Are you new to Accessbot?
                          <Link
                            value={'register'}
                            id={'register'}
                            to="/register"
                            variant="contained"
                            tabIndex={104}
                            className={classes.link}
                          >
                            Try For Free
                          </Link>
                        </p>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </Grid>
                <Grid item sm={1}></Grid>
              </Grid>
            </main>

            <Footer />
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(WithUser(withSnackbar(Login)));
