import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator-vivek';
import AuthenticatedPage from "../AuthenticatedPage";
import DataTable from '../common/DataTable';
import { showSnackbarError } from '../common/Utils';
import WithDashboardScan from './withDashboardScan';
import Chip from '@material-ui/core/Chip';


const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        justifyContent: 'center',
    },
    media: {
        height: 200,
        paddingTop: '56.25%', // 16:9
    },
    paper: {
        padding: theme.spacing.unit,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    scancolor: {
        color: '#26a69',
    },
    url: {
        width: 80, wordWrap: 'break-word'
    },
    layout: {
        width: 'auto',
        minHeight: "400px",
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 9,
        paddingTop: theme.spacing.unit * 8,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            marginBottom: 100,
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 'auto'
        },
    },
    color: {
        color: theme.name.color,
    },
    name: {
        color: theme.name.color,
        fontSize: theme.typography.pxToRem(26),
        lineHeight: theme.name.lineHeight
    },
    options: {
        display: 'inline',
        paddingRight: 5,
        color: theme.options.color,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.options.lineHeight
    },
    formcontrollabel: {
        paddingLeft: 15,
    },
    label: {
        fontSize: theme.typography.pxToRem(25),
        position: theme.label.position,
        textAlign: theme.label.textAlign
    },
    button: {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.color,
        width: 240,
        padding: theme.button.padding,
        '&:hover': {
            background: theme.button.backgroundColor,
        },
    },
    p: {
        fontSize: theme.typography.pxToRem(36),
        color: theme.p.color,
        fontWeight: theme.p.fontWeight
    },
});

const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

class BrokenLinks extends Component {
    constructor(props) {
        super(props);
        const { url } = this.props.currentUrl.scanUrlResult;
        this.state = {
            url,
            disableCall: false,
            data: null,
            status: null,
            tableData: null
        }
    }

    async componentDidMount() {
        ValidatorForm.addValidationRule('isUrl', (value) => {
            if (!pattern.test(value)) {
                return true;
            }
            return true;
        });
    }

    handleSubmit = async () => {
        this.setState({ disableCall: true, data: null, tableData: null })
        this.scanSeoErrors()
    }

    scanSeoErrors = async () => {
        let data = {
            urls: [this.state.url]
        }
        axios.post('/api/user/seoAnalyzer', data)
            .then(res => {
                let tableData = this.prepareTableData(res.data.data.pages[0].issues)
                this.setState({
                    disableCall: false,
                    data: res.data,
                    tableData
                });
            },
                (error) => {
                    this.setState({ disableCall: false, data: null })
                }
            )
    }

    prepareTableData = (data) => {
        let tableData = []
        if (data) {
            let errors = data.errors
            let warnings = data.warnings
            let notices = data.notices
            let errorArr = Object.values(errors)
            for (let errorObj of errorArr) {
                if (errorObj.list && errorObj.list.length > 0) {
                    for (let urlObj of errorObj.list) {
                        let rowData = []
                        rowData.push(urlObj.url)
                        rowData.push(errorObj.title)
                        rowData.push(errorObj.description)
                        rowData.push(errorObj.score)
                        tableData.push(rowData)
                    }
                } else {
                    let rowData = []
                    rowData.push(this.state.url)
                    rowData.push(errorObj.title)
                    rowData.push(errorObj.description)
                    rowData.push(errorObj.score)
                    tableData.push(rowData)
                }
            }
        }
        return tableData;
    }

    sleep = async (ms) => {
        var _resolve, _reject;
        var promise = new Promise((resolve, reject) => {
            _reject = reject;
            _resolve = resolve;
        });
        setTimeout(() => {
            _resolve('Foo');
        }, ms);
        return promise;
    }


    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value, image: null })
    }

    render() {
        const { classes, currentUser } = this.props;
        const columns = [
            {
                name: "URL",
                options: {
                    filter: false
                },
            },
            {
                name: "Title",
                options: {
                    filter: true
                },
            },
            {
                name: "Description",
                options: {
                    filter: true
                },
            },
            {
                name: "Score",
                options: {
                    filter: true
                },
            }
        ]
        return (
            <React.Fragment>
                <main className={classes.layout1}>
                    <Grid container>
                        <Grid item style={{ paddingTop: 0 }} align="left">
                            <div className={classes.name}>SEO Dashboard</div><br />
                        </Grid>
                    </Grid>
                    <ValidatorForm className={classes.root} onSubmit={() => this.setState({ isFromHistory: false, scannedOn: new Date() }, () => this.handleSubmit())} onError={() => showSnackbarError("Invalid URL", this)}>
                        <Grid container >
                            <Grid item sm={4} style={{ paddingLeft: 10 }}>
                                <TextValidator
                                    label="Web URL"
                                    id="url"
                                    tabIndex={100}
                                    name="url"
                                    value={this.state.url}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                    validators={['required', 'isUrl']}
                                    errorMessages={['']}
                                    InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item sm={4} style={{ paddingTop: 50 }}>
                                <Button type='submit' value='submit' tabIndex={101} disabled={this.state.disableCall} className={classes.button}>
                                    Scan for errors
                                <SendIcon className={classes.rightIcon} />
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                    {this.state.disableCall &&
                        <Grid container>
                            <Grid item sm={4}>
                                <div style={{ paddingLeft: 35 }}>{"Scanning SEO Errors ....."}<LinearProgress className={classes.scancolor} /></div>
                            </Grid>
                            <Grid item sm={8}>
                            </Grid>
                        </Grid>
                    }
                    {this.state.tableData &&
                        <>
                            {this.state.tableData.length > 0 &&
                                <Grid container >
                                    <Grid item sm={10} style={{ paddingLeft: 10, marginTop: 10 }}>
                                        <DataTable
                                            key={"seo-errors"}
                                            title="SEO Errors"
                                            columns={columns}
                                            data={this.state.tableData}
                                            expandableRows={false}
                                            selectableRows={false}
                                            download={true}
                                            filter={true}
                                            rowsPerPage={20}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </>
                    }
                </main>
            </React.Fragment >
        );
    }
}
BrokenLinks.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(AuthenticatedPage(WithDashboardScan(withSnackbar(BrokenLinks)))));
